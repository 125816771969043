import React, { useEffect, useState } from "react";
import { useLanguageStore } from "../../hooks/useLanguage";
import Modal from "react-bootstrap/Modal";
import { FaSave, FaTimes, FaUserPlus, FaEye, FaEyeSlash } from "react-icons/fa";
import { getCookie } from "../../libs/utils";
import toast from "react-hot-toast";
import { ConfirmModal } from "./ConfirmModal";

export const RealModalBody = (props) => {
  const { t } = useLanguageStore();
  const {
    show,
    onShow,
    onHide,
    theme,
    messageType,
    manualRecipients,
    setManualRecipients,
  } = props;
  const [recipient, setRecipient] = useState({
    email: "",
    phone: "",
    chat_id: "",
  });

  const recipientChanged = (e) => {
    let newRecipient = { email: "", phone: "", chat_id: "" };
    if (messageType === "sms") {
      newRecipient.phone = e.target.value;
    }
    if (messageType === "whatsapp") {
      newRecipient.phone = e.target.value;
    }
    if (messageType === "email") {
      newRecipient.email = e.target.value;
    }
    if (messageType === "telegram") {
      newRecipient.chat_id = e.target.value;
    }
    setRecipient(newRecipient);
    //   setRecipient({...recipient, chat_id: e.target.value});
  };

  const fieldText = () => {
    if (messageType === "sms") {
      return t("Phone Number");
    }
    if (messageType === "whatsapp") {
      return t("Phone Number");
    }
    if (messageType === "email") {
      return t("Email address");
    }
    if (messageType === "telegram") {
      return t("Chat ID");
    }
  };

  const fieldValue = () => {
    if (messageType === "sms") {
      return recipient.phone;
    }
    if (messageType === "whatsapp") {
      return recipient.phone;
    }
    if (messageType === "email") {
      return recipient.email;
    }
    if (messageType === "telegram") {
      return recipient.chat_id;
    }
  };

  const fieldType = () => {
    if (messageType === "sms") {
      return "phone";
    }
    if (messageType === "whatsapp") {
      return "phone";
    }
    if (messageType === "email") {
      return "email";
    }
    if (messageType === "telegram") {
      return "text";
    }
  };

  return (
    <>
      <Modal.Body className={`box-shadow-${theme} rounded-3`}>
        <div className="row">
          <div className="d-flex col-lg-12">
            <h3 className={"d-flex"}>
              {t("Add recipient")}
              {/* <span className={"ms-1 badge bg-primary-lt"}>{accountToEdit?.name}</span> */}
              {/* <span className={"ms-1 badge bg-danger-lt d-flex align-items-center justify-items-center cursor-pointer"} onClick={deleteAccountClicked}>
                <FaTrash size={10} className={"me-1 cursor-pointer"} />
                {t("Delete")}
              </span> */}
            </h3>
            <FaTimes
              size={20}
              className={"ms-auto cursor-pointer"}
              onClick={onHide}
            />
          </div>
        </div>

        <div className="pt-0">
          <form action="#" className={"row"}>
            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label htmlFor="field">
                  {messageType === "sms" && t("Phone Number")}
                  {messageType === "whatsapp" && t("Phone Number")}
                  {messageType === "email" && t("Email address")}
                  {messageType === "telegram" && t("Chat ID")}
                </label>
                <input
                  onInput={recipientChanged}
                  value={fieldValue()}
                  required={true}
                  type={fieldType()}
                  className="form-control"
                  id="field"
                  placeholder={fieldText()}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <button
                onClick={() => {}}
                type={"button"}
                className={`btn btn-primary w-100`}
              >
                <FaUserPlus className={"me-2"} />
                {t("Add")}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </>
  );
};

export const MessageManuallyAddRecipientModal = (props) => {
  let { show, onShow, onHide, theme } = props;
  return (
    <>
      <Modal
        size={"md"}
        show={show}
        onHide={onHide}
        // centered={true}
        // className={`modal-blur`}
        className={`modal-custom-backdrop-${theme}`}
        backdrop={"static"}
        // dialogClassName={`box-shadow-${theme}`}
      >
        {show && <RealModalBody {...props} />}
      </Modal>
    </>
  );
};
