import React, { useEffect, useState } from "react";
import { useLanguageStore } from "../../hooks/useLanguage";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import {
  FaHouse,
  FaMoneyBills,
  FaUsers,
  FaGear,
  FaGears,
  FaA,
  FaO,
  FaN,
  FaD,
  FaI,
  FaM,
  FaP,
  FaR,
} from "react-icons/fa6";
import {
  FaAngleDown,
  FaAngleUp,
  FaBell,
  FaClock,
  FaConciergeBell,
} from "react-icons/fa";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import { usePermissions } from "../../hooks/PermissionContext";
import { LuAlarmClock } from "react-icons/lu";
import NavDropdown from "react-bootstrap/NavDropdown";

const textStyle = {
  fontSize: "1.3rem",
};

const PageLogo = () => {
  return (
    <>
      <FaA size={20} />
      <FaD size={20} />
      <FaM size={20} />
      <FaI size={20} />
      <FaN size={20} />
      <FaP size={20} color={"var(--bs-primary)"} />
      <FaR size={20} color={"var(--bs-primary)"} />
      <FaO size={20} color={"var(--bs-primary)"} />
    </>
  );
};

export const NavModal = (props) => {
  const { t } = useLanguageStore();
  const permissions = usePermissions();
  const {
    show,
    handleCloseNavModal,
    handleShowNavModal,
    theme,
    changePassword,
  } = props;
  const [language, setLanguage] = useState("en");

  const accountingUrl = `${window.location.origin}/acc?theme=${theme}`;

  const DropdownMenu = (props) => {
    const [expanded, setExpanded] = useState(false);
    const { title, className, style } = props;
    return (
      <>
        <div className={`${className}`} onClick={() => setExpanded(!expanded)}>
          <span
            className={"cursor-pointer d-flex align-items-center flex-row"}
            style={style}
          >
            {title}
            {expanded ? <FaAngleUp /> : <FaAngleDown />}
          </span>
        </div>
        {expanded && (
          <div className="bg-muted-lt">
            {props?.children?.map((child, index) => {
              return (
                <div key={index} onClick={handleCloseNavModal}>
                  {child}
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Modal
        size={"xl"}
        show={show}
        onHide={handleCloseNavModal}
        className={`modal-custom-backdrop-${theme}`}
        // className={"modal-blur"}
      >
        <Modal.Body>
          <div className="row">
            <div
              className="col-lg-12 d-flex align-items-center justify-content-center"
              style={{ flexDirection: "row" }}
            >
              <PageLogo />
            </div>
            <div className="col-lg-12">
              <div className="d-flex flex-column mt-3">
                <LanguageSwitcher
                  language={language}
                  setLanguage={setLanguage}
                />
                <Link
                  to={"/"}
                  className="dropdown-item p-2 py-2 d-flex align-items-center flex-row"
                  onClick={handleCloseNavModal}
                >
                  <FaHouse size={20} />
                  <span className="ms-2" style={textStyle}>
                    {t("Home")}
                  </span>
                </Link>
                {/*<DropdownMenu className={"dropdown-item p-2 py-2"} title={<>*/}
                {/*  <FaMoneyBills size={20} />*/}
                {/*  <span className="ms-2" style={textStyle}>{t("Finances")}</span>*/}
                {/*</>}>*/}
                {/*  <Link to={"/finance/accounts"} className="dropdown-item p-2 py-2 ms-4"*/}
                {/*    style={textStyle}>*/}
                {/*    {t("Accounts")}*/}
                {/*  </Link>*/}
                {/*  <Link to={"/finance/expenses"} className="dropdown-item p-2 py-2 ms-4" style={textStyle}>*/}
                {/*    {t("Expense")}*/}
                {/*  </Link>*/}
                {/*  <Link to={"/finance/income-setup"} className="dropdown-item p-2 py-2 ms-4"*/}
                {/*    style={textStyle}>*/}
                {/*    {t("Income Setup")}*/}
                {/*  </Link>*/}
                {/*  {permissions?.permissions?.includes("view_transctn") &&*/}
                {/*    <Link to={"/finance/bankstatement"} className="dropdown-item p-2 py-2 ms-4"*/}
                {/*      style={textStyle}>*/}
                {/*      {t("Process Bankstatement")}*/}
                {/*    </Link>*/}
                {/*  }*/}
                {/*  {permissions?.permissions?.includes("view_transctn") &&*/}
                {/*    <Link to={"/finance/reports"} className="dropdown-item p-2 py-2 ms-4"*/}
                {/*      style={textStyle}>*/}
                {/*      {t("Reports")}*/}
                {/*    </Link>*/}
                {/*  }*/}
                {/*</DropdownMenu>*/}
                <DropdownMenu
                  className={"dropdown-item p-2 py-2"}
                  title={
                    <>
                      <FaMoneyBills size={20} />
                      <span className="ms-2" style={textStyle}>
                        {t("Accounting")}
                      </span>
                    </>
                  }
                >
                  <Link
                    to={"/accounting"}
                    className="dropdown-item p-2 py-2 ms-4"
                    style={textStyle}
                  ></Link>
                  <a
                    href={accountingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dropdown-item p-2 py-2 ms-4"
                    style={textStyle}
                  >
                    {t("Home")}
                  </a>
                </DropdownMenu>

                <Link
                  to={"/members"}
                  className="dropdown-item p-2 py-2"
                  onClick={handleCloseNavModal}
                >
                  <FaUsers size={20} />
                  <span className="ms-2" style={textStyle}>
                    {t("Members")}
                  </span>
                </Link>

                <Link
                  to={"/settings"}
                  className="dropdown-item p-2 py-2"
                  onClick={handleCloseNavModal}
                >
                  <FaGear size={20} />
                  <span className="ms-2" style={textStyle}>
                    {t("Database Settings")}
                  </span>
                </Link>

                <DropdownMenu
                  className={"dropdown-item p-2 py-2"}
                  title={
                    <>
                      <FaGears size={20} />
                      <span className="ms-2" style={textStyle}>
                        {t("Other Settings")}
                      </span>
                    </>
                  }
                >
                  <Link
                    to={"/other_settings/company"}
                    className="dropdown-item p-2 py-2 ms-4"
                    style={textStyle}
                  >
                    {t("Company")}
                  </Link>
                  {permissions?.permissions?.includes("view_groups") && (
                    <Link
                      to={"/other_settings/groups"}
                      className="dropdown-item p-2 py-2 ms-4"
                      style={textStyle}
                    >
                      {t("Groups")}
                    </Link>
                  )}
                  <Link
                    to={"/other_settings/usermanagement"}
                    className="dropdown-item p-2 py-2 ms-4"
                    style={textStyle}
                  >
                    {t("User Management")}
                  </Link>
                  <Link
                    to={"/other_settings/messaging"}
                    className="dropdown-item p-2 py-2 ms-4"
                    style={textStyle}
                  >
                    {t("Messaging")}
                  </Link>
                </DropdownMenu>

                <DropdownMenu
                  className={"dropdown-item p-2 py-2"}
                  title={
                    <>
                      <LuAlarmClock size={20} />
                      <span className="ms-2" style={textStyle}>
                        {t("Reminders")}
                      </span>
                    </>
                  }
                >
                  {permissions?.permissions?.includes("view_members") && (
                    <Link
                      to={"/reminders/birthdays"}
                      className="dropdown-item p-2 py-2 ms-4"
                      style={textStyle}
                    >
                      {t("Upcoming Birthdays")}
                    </Link>
                  )}
                  {permissions?.permissions?.includes("view_events") && (
                    <Link
                      to={"/reminders/events"}
                      className="dropdown-item p-2 py-2 ms-4"
                      style={textStyle}
                    >
                      {t("Upcoming Events")}
                    </Link>
                  )}
                </DropdownMenu>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
