import React, { useEffect, useState, useRef } from "react";
import { useLanguageStore } from "../../hooks/useLanguage";
import Modal from "react-bootstrap/Modal";
import {
  FaArrowDown,
  FaArrowUp,
  FaPaperPlane,
  FaSave,
  FaTimes,
  FaUserPlus,
  FaSearch,
} from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { getCookie } from "../../libs/utils";
import toast from "react-hot-toast";
import { ConfirmModal } from "./ConfirmModal";
import { FamilyMemberModal } from "./FamilyMemberModal";
import { IconArrowUp, IconArrowDown } from "@tabler/icons-react";
import Dropdown from "react-bootstrap/Dropdown";
import { BirthdayMessageModal } from "./BirthdayMessageModal";
import Popover from "react-bootstrap/Popover";
import { usePermissions } from "../../hooks/PermissionContext";

export const AddAttendeesModal = (props) => {
  const { t } = useLanguageStore();
  const permissions = usePermissions();
  const { show, onShow, onHide, eventId, user } = props;
  const debounceTimeout = useRef(null);
  const [members, setMembers] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const [attendeesReminded, setAttendeesReminded] = useState([]);
  const [confirmModalText, setConfirmModalText] = useState(
    "Are you sure you want to add this family?"
  );
  const [confirmModalTitle, setConfirmModalTitle] = useState("Add Family");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [newAttendeesSaved, setNewAttendeesSaved] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [filteredAttendees, setFilteredAttendees] = useState(attendees);

  const handleInputChange = (e) => {
    const searchPhrase = e.target.value;
    setSearchItem(searchPhrase);
    // const filteredItems = attendees.filter((attendee) => {
    //     if (attendee?.Names !== null) {
    //         return attendee.Names.toLowerCase().includes(searchItem.toLowerCase());
    //     }
    //     return false;
    // });
    // setFilteredAttendees(filteredItems);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout to call the search function after 1000ms
    debounceTimeout.current = setTimeout(() => {
      // memberQuickSearch(value);
      if (searchPhrase?.length >= 3) {
        getAttendees_api(searchPhrase).then((data) => {
          setAttendees(data);
          setFilteredAttendees(data);
        });
      } else {
        setAttendees([]);
        setFilteredAttendees([]);
      }
    }, 1000);
  };

  useEffect(() => {
    if (eventId) {
      setSelectedEvent((prevState) => {
        return eventId;
      });
      if (searchItem?.length >= 3) {
        getAttendees();
      }
    }
  }, [eventId]);

  const getAttendees_api = (phrase) => {
    return new Promise((resolve, reject) => {
      fetch(`/apiv2/get_new_attendees/${phrase}/`)
        .then((res) => res.json())
        // .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const getAttendees = () => {
    if (searchItem?.length >= 3) {
      getAttendees_api(searchItem)
        .then((data) => {
          setAttendees(data);
          setFilteredAttendees(data);
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const updateEventAttendees_api = () => {
    return new Promise((resolve, reject) => {
      let url = `/apiv2/update_event_attendees/`;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: JSON.stringify({
          event: selectedEvent,
        }),
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.errorCode === 0) {
            resolve(data);
          } else {
            console.log(data.errorMessage);
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const updateEventAttendees = () => {
    updateEventAttendees_api()
      .then((data) => {
        toast.custom(t("Attendees updated Successfully"));
      })
      .catch((err) => {
        toast.error(t("Attendees updated Unsuccessful"));
      });
    // alert(`Birthday Message Sent: ${message}: ${selectedbirthday.BirthDate}`);
    setNewAttendeesSaved(true);
  };

  const SendMessage_api = (data, eventId) => {
    return new Promise((resolve, reject) => {
      let url = `/apiv1/generate_sms_reminder/`;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: JSON.stringify({
          recipient: [data.id],
          sms: true,
          subject: eventId.description,
          message: t("reminder_message", {
            description: eventId.description,
            startdate: eventId.startdate,
            venue: eventId.venue,
          }),
          // message: "This is a reminder of " + eventId.description + " on " + eventId.startdate + " at " + eventId.venue,
        }),
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.errorCode === 0) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  const sendReminder = (e, selectedEvent) => {
    if (newAttendeesSaved) {
      let eventparticipants = { ...selectedEvent };
      setAttendeesReminded(eventparticipants.participants);
      eventparticipants.participants.forEach((attendee) => {
        SendMessage_api({ id: attendee.id })
          .then((data) => {})
          .catch((err) => {});
      });
      onHide();
      toast.custom(
        t("success_reminder_toast", {
          description: eventId.description,
          startdate: eventId.startdate,
          venue: eventId.venue,
        })
      );
      // toast.success("Reminders sent successfully: " + "This is a reminder of " + eventId.description + " on " + eventId.startdate + " at " + eventId.venue)
    } else {
      toast.custom(t("Save New attendees before you proceed"));
    }
  };

  const handleShowReminderModal = () => {
    setShowReminderModal(true);
  };

  const handleCloseReminderModal = () => {
    setShowReminderModal(false);
  };

  const onModalHide = () => {
    if (members?.length > 0) {
      setConfirmModalTitle(t("Lose Changes?"));
      setConfirmModalText(t("Are you sure you want to lose these changes?"));
      setShowConfirmModal(true);
    } else {
      onHide();
    }
  };

  const AddAttendees = (e, attendee) => {
    let newEvent = { ...selectedEvent };
    let newAttendees = attendees.filter((part) => part.id !== attendee.id);
    newEvent.participants.push(attendee);
    setAttendees(newAttendees);
    setSelectedEvent(newEvent);
  };

  const RemoveAttendee = (e, participant) => {
    let newEvent = { ...selectedEvent };
    let newParticipants = newEvent.participants.filter(
      (part) => part.id !== participant.id
    );
    newEvent.participants = newParticipants;
    let newAttendees = [...attendees, participant];
    setAttendees(newAttendees);
    setSelectedEvent(newEvent);
  };

  return (
    <div>
      <Modal
        size={"md"}
        show={show}
        onHide={onHide}
        centered={true}
        className={"modal-blur "}
        backdrop={"static"}
      >
        <Modal.Body style={{ maxHeight: "70dvh", overflowY: "auto" }}>
          <div className="row ">
            <div
              className="d-flex col-lg-12 flex-row"
              style={{ justifyContent: "space-between" }}
            >
              <h2>
                {t("Current attendees", {
                  description: selectedEvent?.description,
                  participants: selectedEvent?.participants.length,
                })}
              </h2>
              <span>
                <FaTimes
                  size={20}
                  className={"cursor-pointer"}
                  onClick={onModalHide}
                />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="table-responsive mb-2 border border-primary rounded"
                style={{ overflowY: "auto", maxHeight: "30dvh" }}
              >
                <table className="table table-striped">
                  <tbody>
                    {selectedEvent?.participants.map((part, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <FaArrowDown
                              color={"orangered"}
                              className="me-2"
                              tooltip={"Remove Attendee"}
                              onClick={(e) => RemoveAttendee(e, part)}
                            />
                            {part.Names} {part.Surname}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <span className="d-flex flex-row justify-content-between mb-3">
                <button
                  className="btn rounded btn-outline-primary"
                  onClick={updateEventAttendees}
                >
                  <FaSave size={20} />
                  <span className={"ms-2"}>{t("Save Attendees")}</span>
                </button>
                {permissions?.permissions?.includes("send_messages") && (
                  <button
                    className="btn rounded btn-outline-primary"
                    onClick={(e) => sendReminder(e, selectedEvent)}
                  >
                    <FaPaperPlane size={20} />
                    <span className="ms-2">{t("Send Reminder")}</span>
                  </button>
                )}
                <BirthdayMessageModal
                  selectedbirthday={attendeesReminded}
                  show={showReminderModal}
                  onShow={handleShowReminderModal}
                  onHide={handleCloseReminderModal}
                  user={user}
                />
              </span>
              <div className="">
                <span className="h4">{t("Select Attendees")}</span>
                <div>
                  <input
                    type="text"
                    value={searchItem}
                    onChange={handleInputChange}
                    placeholder={t("Type to search...")}
                    className="form-control"
                  ></input>
                </div>
              </div>
              {filteredAttendees.length === 0 ? (
                <span className="px-1 text-muted">
                  {t("No results for search")}
                </span>
              ) : (
                <div
                  className="mt-3 rounded border border-primary"
                  style={{ overflowY: "auto", maxHeight: "30dvh" }}
                >
                  <table className="table table-striped">
                    <tbody>
                      {filteredAttendees?.map((attendee, index) => {
                        return (
                          <tr>
                            <td className="p-0 py-2 ps-3">
                              <FaArrowUp
                                color={"limegreen"}
                                className="me-2"
                                onClick={(e) => {
                                  AddAttendees(e, attendee);
                                }}
                                title={t("Add Attendee")}
                                tooltip={t("Add Attendee")}
                              />
                              {attendee.Names} {attendee.Surname}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
