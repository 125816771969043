import React, { useEffect } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { TopNav } from "./TopNav";
import { Toaster } from "react-hot-toast";
import { useAuth } from "../hooks/useAuth";

export const Base = (props) => {
  const outlet = useOutlet();
  const { user, auth } = useAuth();

  useEffect(() => {
    auth().then((logged_in) => {
      if (logged_in === false) {
        return <Navigate to={"/login"} />;
      }
    });
  }, []);

  if (!user) return <Navigate to={"/login"} />;

  return (
    <div className="app" style={{ height: "100%" }}>
      <Toaster />
      <div className="page" style={{ height: "100%", overflow: "hidden" }}>
        <TopNav {...props} />
        <div
          className="py-5 content"
          style={{ height: "100%", overflow: "auto" }}
        >
          {outlet}
        </div>
      </div>
    </div>
  );
};
