// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {
    display: flex;
    flex-wrap: wrap;
}

.form-group .k-form-field-wrap {
    flex: 1 1 45%;
    margin: 10px;
}

@media (max-width: 768px) {
    .form-group .k-form-field-wrap {
        flex: 1 1 100%;
    }
}

`, "",{"version":3,"sources":["webpack://./src/styles/CompanySetup.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".form-group {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.form-group .k-form-field-wrap {\n    flex: 1 1 45%;\n    margin: 10px;\n}\n\n@media (max-width: 768px) {\n    .form-group .k-form-field-wrap {\n        flex: 1 1 100%;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
