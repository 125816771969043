import React from "react";

export const Paginator = (props) => {
  let { page, setPage, totalPages, className, containerClassName } = props;
  let buttons = [];
  // add as many as 5 buttons to the array, unless there are less than 5 pages, then add all pages
  buttons.push({ page: 1, text: "<<" });
  if (totalPages > 5) {
    if (page < 3) {
      for (let i = 1; i <= 5; i++) {
        buttons.push({ page: i, text: i });
      }
    } else if (page > totalPages - 2) {
      for (let i = totalPages - 4; i <= totalPages; i++) {
        buttons.push({ page: i, text: i });
      }
    } else {
      for (let i = page - 2; i <= page + 2; i++) {
        buttons.push({ page: i, text: i });
      }
    }
  } else {
    for (let i = 1; i <= totalPages; i++) {
      buttons.push({ page: i, text: i });
    }
  }
  buttons.push({ page: totalPages, text: ">>" });

  return (
    <>
      <div className={`d-flex justify-content-center ${containerClassName}`}>
        {buttons?.map((button, index) => {
          let buttonClassName = `${className} btn mx-1`;
          if (parseInt(button.text) === page) {
            buttonClassName += ` btn-primary`;
          }
          return (
            <button
              key={index}
              onClick={() => setPage(button.page)}
              className={buttonClassName}
            >
              {button.text}
            </button>
          );
        })}
      </div>
    </>
  );
};
