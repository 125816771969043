import { getCookie } from "./utils";

// Utility functions

const handleFetchResponse = async (response) => {
  if (!response.ok) {
    const contentType = response.headers.get("content-type");
    let errorMsg = "Something went wrong.";
    if (contentType && contentType.includes("application/json")) {
      const err = await response.json();
      errorMsg = Object.values(err).flat().join(" ") || errorMsg;
    }
    throw new Error(errorMsg);
  }
  return response.status !== 204 ? response.json() : null;
};

const fetchHelper = (url, { method = "GET", body } = {}) => {
  const headers = { "Content-Type": "application/json" };
  if (method !== "GET") {
    headers["X-CSRFToken"] = getCookie("csrftoken");
  }
  return fetch(url, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  }).then(handleFetchResponse);
};

// Members
export const fetchFamily = (id) => fetchHelper(`/apiv2/family/${id}/`);
export const fetchMember = (id) => fetchHelper(`/apiv2/member/${id}/`);
export const fetchMembers = ({ page, order, dir, search, limit }) => fetchHelper(`/apiv2/members/?page=${page}&sort_order=${order}&sort_by=${dir}&search=${search}&limit=${limit}` );
export const fetchMemberVisits = () => fetchHelper(`/apiv2/visits/`);
export const fetchMemberSearch = (phrase) => fetchHelper(`/apiv2/member_quick_search/`, { method: "POST", body: { phrase } });
export const fetchMemberDelete = (id) => fetchHelper(`/apiv2/delete_member/${id}`, { method: "DELETE" });
export const fetchAddVisit = (data) => fetchHelper(`/apiv2/add_visit/`, { method: "POST", body: data });
export const fetchVisitReasons = () => fetchHelper(`/apiv2/get_visit_reasons/`);
export const fetchStaff = () => fetchHelper(`/apiv2/get_staff/`);
export const fetchTitles = () => fetchHelper(`/apiv2/titles/`);
export const fetchAddMember = (data) => fetchHelper(`/apiv2/member/`, { method: "POST", body: { data } });
export const fetchMemberGroups = (id) => fetchHelper(`/apiv2/user_groups/${id}/`)

// export const fetchEditMember = (data) => fetchHelper(`/apiv2/member/`, { method: "PUT", body: data });
export const fetchEditMember = (data) => {
  return fetch(`/apiv2/member/`, {
    method: "PUT",
    headers: {
      // 'Content-Type': 'application/json', // TODO: need to fix this
      "X-CSRFToken": getCookie("csrftoken"),
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);
};

// Messages
export const fetchGetMessageCredits = () => fetchHelper("/apiv2/message_credits/");
export const fetchAddCredits = (credit_type, credits) => fetchHelper("/apiv2/message_credits/", { method: "POST", body: { credit_type, credits }, });
export const fetchMessageHistory = (days, type) => fetchHelper("/apiv2/message_history/?days=" + days + "&type=" + type);
export const fetchMessageMemberSearch = (phrase, type_id) => fetchHelper("/apiv2/message_members_search/?phrase=" + phrase + "&type_id=" + type_id);

// Professions
export const fetchProfessions = () => fetchHelper("/apiv2/professions/");
export const fetchAddProfession = (name) => fetchHelper("/apiv2/professions/", { method: "POST", body: { name } });
export const fetchEditProfession = (id, name) => fetchHelper("/apiv2/professions/", { method: "PUT", body: { id, name } });
export const fetchDeleteProfession = (id) => fetchHelper("/apiv2/professions/", { method: "DELETE", body: { id } });

// Groups
export const fetchGroups = () => fetchHelper("/apiv2/groups/");
export const fetchAddGroup = (name, parent_group, users) => fetchHelper("/apiv2/groups/", { method: "POST", body: { name, parent_group, users }, });
export const fetchEditGroup = (id, name, parent_group, users) => fetchHelper("/apiv2/groups/", { method: "PUT", body: { id, name, parent_group, users }, });
export const fetchDeleteGroup = (id) => fetchHelper("/apiv2/groups/", { method: "DELETE", body: { id } });

