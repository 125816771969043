import React, { useEffect, useState, useRef } from "react";
import { CreditPurchaseForm } from "../components/CreditPurchaseForm";

import toast from "react-hot-toast";
import { useLanguageStore } from "../hooks/useLanguage";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../hooks/useTheme";
import { usePermissions } from "../hooks/PermissionContext";
import { useWebsocket } from "../hooks/useWebsocket";
import { useEnv } from "../hooks/useEnv";
import { fetchMessageHistory, fetchMessageMemberSearch } from "../libs/api";
import {
  FaEnvelope,
  FaTelegram,
  FaRegMessage,
  FaWhatsapp,
} from "react-icons/fa6";

function MessageHistory(props) {
  const { t } = useLanguageStore();
  const [recipients, setRecipients] = useState([]);
  const [messageType, setMessageType] = useState("sms");
  const [sendMessageType, setSendMessageType] = useState("sms");
  const [messageContent, setMessageContent] = useState("");
  const [messageSubject, setMessageSubject] = useState("");
  const [wsMessage, setWsMessage] = useState(null);
  const { env } = useEnv();
  // const { ws_send, ws_status, message, initWebsocket, ws_connected } = useWebsocket(env?.REACT_APP_ADMINPRO_WS_PORT);
  const [messages, setMessages] = useState([]);
  const [messagesLoading, setMessagesLoading] = useState(true);
  const [daysFilter, setDaysFilter] = useState("7");
  const [typeFilter, setTypeFilter] = useState("all");
  const [memberSearchPhrase, setMemberSearchPhrase] = useState("");
  const [memberSearchType, setMemberSearchType] = useState(1);
  const [memberResults, setMemberResults] = useState([]);
  const [memberSearchLoading, setMemberSearchLoading] = useState(false);
  const debounceTimeout = useRef(null);

  const { user, creditData } = props;

  useEffect(() => {
    setMessagesLoading(true);
    fetchMessageHistory(daysFilter, typeFilter).then((data) => {
      setMessages(data?.data);
      setMessagesLoading(false);
    });
  }, []);

  useEffect(() => {
    setMessagesLoading(true);
    fetchMessageHistory(daysFilter, typeFilter).then((data) => {
      setMessages(data?.data);
      setMessagesLoading(false);
    });
  }, [daysFilter, typeFilter]);

  const typeFilterChange = (e) => {
    setTypeFilter(e.target.value);
  };

  const daysFilterChange = (e) => {
    setDaysFilter(e.target.value);
  };

  const HistoryItem = ({ msg }) => {
    return (
      <tr>
        <td>{msg?.dt_created}</td>
        <td>
          {msg?.user?.first_name} {msg?.user?.Names}
        </td>
        <td>
          {msg?.type?.id === 1 && (
            <FaEnvelope color={"#0ca678"} className="me-2" />
          )}
          {msg?.type?.id === 2 && (
            <FaRegMessage color={"#f59f00"} className="me-2" />
          )}
          {msg?.type?.id === 3 && (
            <FaTelegram color={"#4299e1"} className="me-2" />
          )}
          {msg?.type?.id === 4 && (
            <FaWhatsapp color={"#0ca678"} className="me-2" />
          )}
          {msg?.type?.name}
        </td>
        <td>{msg?.status?.name}</td>
      </tr>
    );
  };

  const sendMessageTypeChange = (e) => {
    setSendMessageType(e.target.value);
  };

  const HistoryTable = () => {
    if (messagesLoading) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "40rem", height: "40rem" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">{t("Loading")}...</span>
          </div>
        </div>
      );
    } else if (!messagesLoading && messages.length === 0) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "40rem", height: "40rem" }}
        >
          <h2>{t("No messages found")}</h2>
        </div>
      );
    } else {
      return (
        <div
          className="m-0 overflow-auto"
          style={{ minHeight: "40rem", height: "40rem" }}
        >
          <table className="table table-striped">
            <thead className="sticky-top">
              <tr>
                <th>{t("Date")}</th>
                <th>{t("Recipient")}</th>
                <th>{t("Message")}</th>
                <th>{t("Status")}</th>
              </tr>
            </thead>
            <tbody>
              {messages.map((msg, index) => (
                <HistoryItem key={index} msg={msg} />
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="card">
            <div className="p-2 px-3 d-flex justify-content-between align-items-center">
              <h2 className="m-0">{t("Messages")}</h2>

              <div className="d-flex flex-row">
                <div className="form-group">
                  <select
                    disabled={messagesLoading}
                    value={daysFilter}
                    onInput={daysFilterChange}
                    name=""
                    className="form-control"
                    id=""
                  >
                    <option value="1">{t("Today")}</option>
                    <option value="7">{t("This week")}</option>
                    <option value="30">{t("This month")}</option>
                    <option value="all">{t("All")}</option>
                  </select>
                </div>

                <div className="form-group ms-1">
                  <select
                    disabled={messagesLoading}
                    value={typeFilter}
                    onInput={typeFilterChange}
                    name=""
                    className="form-control"
                    id=""
                  >
                    <option value="sms">SMS</option>
                    <option value="email">Email</option>
                    <option value="telegram">Telegram</option>
                    <option value="whatsapp">WhatsApp</option>
                    <option value="all">{t("All")}</option>
                  </select>
                </div>
              </div>
            </div>
            <HistoryTable />
          </div>
        </div>
      </div>
    </>
  );
}

export default MessageHistory;
