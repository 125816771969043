// import {FaA, FaE, FaI, FaL, FaN, FaO} from "react-icons/fa6";
import {
  FaHouse,
  FaA,
  FaO,
  FaN,
  FaD,
  FaI,
  FaM,
  FaP,
  FaR,
} from "react-icons/fa6";
import Illustration from "../assets/illustration.svg";
import { useLanguageStore } from "../hooks/useLanguage";
import { useEnv } from "../hooks/useEnv";
import { useAuth } from "../hooks/useAuth";
import { useWebsocket } from "../hooks/useWebsocket";
import React, { useEffect, useState } from "react";
import { getCookie } from "../libs/utils";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

export const Login = (props) => {
  const { t } = useLanguageStore();
  const { env } = useEnv();
  const { user, auth, signin, fetchPermissions } = useAuth();
  const { ws_send, ws_status, message, initWebsocket, ws_connected } =
    useWebsocket(env);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    auth().then((logged_in) => {
      if (logged_in === true) {
        navigate("/");
      }
    });
  }, []);

  const IllustrationLogo = () => {
    return (
      <>
        <img
          height={300}
          src={Illustration}
          alt=""
          className={"d-block mx-auto"}
        />
      </>
    );
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const PageLogo = (props) => {
    const { size } = props;
    return (
      <>
        {/*<FaA size={size} color={"var(--bs-primary)"}/>*/}
        {/*<FaO size={size}/>*/}
        {/*<FaN size={size}/>*/}
        {/*<FaL size={size}/>*/}
        {/*<FaI size={size}/>*/}
        {/*<FaN size={size}/>*/}
        {/*<FaE size={size}/>*/}
        <FaA size={size} />
        <FaD size={size} />
        <FaM size={size} />
        <FaI size={size} />
        <FaN size={size} />
        <FaP size={size} color={"var(--bs-primary)"} />
        <FaR size={size} color={"var(--bs-primary)"} />
        <FaO size={size} color={"var(--bs-primary)"} />
      </>
    );
  };

  const passwordChanged = (e) => {
    setPassword(e.target.value);
  };

  const usernameChanged = (e) => {
    setUsername(e.target.value);
  };

  const doLogin = (e) => {
    e.preventDefault();
    signin({ username, password }).then((success) => {
      if (success) {
        window.location.reload(true);
      } else {
        toast.error(t("Invalid username or password"));
      }
    });
  };

  return (
    <>
      <Toaster />
      <div className="page page-center">
        <div className="container container-normal py-4">
          <div className="row align-items-center g-4">
            <div className="col-lg">
              <div className="container-tight">
                <div className="text-center mb-4">
                  <a href="." className="navbar-brand navbar-brand-autodark">
                    <PageLogo size={20} />
                  </a>
                </div>
                <div className="card card-md">
                  <div className="card-body">
                    <h2 className="h2 text-center mb-4">
                      {t("Login to your account")}
                    </h2>
                    <form onSubmit={doLogin} autoComplete="off" noValidate="">
                      <div className="mb-3">
                        <label className="form-label">{t("Username")}</label>
                        <input
                          onInput={usernameChanged}
                          value={username}
                          type="email"
                          className="form-control"
                          placeholder={t("your@email.com")}
                          autoComplete="off"
                        />
                      </div>
                      <div className="mb-2">
                        <label className="form-label">{t("Password")}</label>
                        <div className="input-group input-group-flat">
                          <input
                            onInput={passwordChanged}
                            value={password}
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder={t("Your password")}
                            autoComplete="off"
                          />
                          <span
                            onClick={toggleShowPassword}
                            className="input-group-text"
                          >
                            <a
                              href="#"
                              className="link-secondary"
                              data-bs-toggle="tooltip"
                              aria-label="Show password"
                              data-bs-original-title="Show password"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                              </svg>
                            </a>
                          </span>
                        </div>
                      </div>
                      <span className="form-label-description">
                        <a href="./forgot-password.html">
                          {t("I forgot password")}
                        </a>
                      </span>
                      <div className="form-footer">
                        <button type="submit" className="btn btn-primary w-100">
                          {t("Sign in")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="text-center text-secondary mt-3">
                  {t("Don't have account yet?")}{" "}
                  <a href="./sign-up.html" tabIndex="-1">
                    {t("Sign up")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg d-none d-lg-block d-flex justify-content-center align-items-center">
              <IllustrationLogo />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
