import React, { useEffect, useState } from "react";
import { CreditPurchaseForm } from "../components/CreditPurchaseForm";
import {
  FaEnvelope,
  FaTelegram,
  FaRegMessage,
  FaWhatsapp,
  FaCreditCard,
} from "react-icons/fa6";
import { fetchGetMessageCredits } from "../libs/api";
import toast from "react-hot-toast";
import { useLanguageStore } from "../hooks/useLanguage";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../hooks/useTheme";
import { usePermissions } from "../hooks/PermissionContext";

function CreditPurchase(props) {
  // const [creditData, setCreditData] = useState({});
  const [loadType, setLoadType] = useState("normal");
  const { t } = useLanguageStore();
  const { user, creditData } = props;

  useEffect(() => {
    // getMessageCredits();
    if (window?.location?.search) {
      // get the search params
      const params = new URLSearchParams(window.location.search);
      const type = params.get("type");
      setLoadType(type);
    }
  }, []);

  useEffect(() => {
    if (loadType == "success") {
      toast.success(t("Transaction is being processed"));
      window.history.replaceState(
        {},
        document.title,
        `${window.location.origin}${window.location.pathname}`
      );
    } else if (loadType == "cancel") {
      toast.error(t("Transaction was canceled"));
      window.history.replaceState(
        {},
        document.title,
        `${window.location.origin}${window.location.pathname}`
      );
    }
  }, [loadType]);

  // const getMessageCredits = () => {
  //   fetchGetMessageCredits().then(all => { return all?.data }).then(data => {
  //     setCreditData(data);
  //   }).catch(err => {
  //     console.log(err);
  //   });
  // }

  return (
    <div className="row">
      <div className="col-xl-3 col-lg-6 col-sm-12 col-md-6 mb-3">
        <div className="card p-3">
          <div className="row">
            <div className="col-6 d-flex justify-content-center align-items-center">
              <FaEnvelope color={"#0ca678"} size={60} />
            </div>
            <div className="col-6">
              <h2 className={"mt-2 mb-0"}>{creditData?.email_credits}</h2>
              <h2 className={"mb-2 mt-0"}>{t("Email Credits")}</h2>
            </div>
          </div>
          <hr className="my-3" />
          <CreditPurchaseForm
            user={user}
            minimumCredits={1000}
            creditType="email_credits"
          />
        </div>
      </div>

      <div className="col-xl-3 col-lg-6 col-sm-12 col-md-6 mb-3">
        <div className="card p-3">
          <div className="row">
            <div className="col-6 d-flex justify-content-center align-items-center">
              <FaRegMessage color={"#f59f00"} size={60} />
            </div>
            <div className="col-6">
              <h2 className={"mt-2 mb-0"}>{creditData?.sms_credits}</h2>
              <h2 className={"mb-2 mt-0"}>{t("SMS Credits")}</h2>
            </div>
          </div>
          <hr className="my-3" />
          <CreditPurchaseForm
            user={user}
            minimumCredits={1}
            creditType="sms_credits"
          />
        </div>
      </div>

      <div className="col-xl-3 col-lg-6 col-sm-12 col-md-6 mb-3">
        <div className="card p-3">
          <div className="row">
            <div className="col-6 d-flex justify-content-center align-items-center">
              <FaTelegram color={"#4299e1"} size={60} />
            </div>
            <div className="col-6">
              <h2 className={"mt-2 mb-0"}>{creditData?.telegram_credits}</h2>
              <h2 className={"mb-2 mt-0"}>{t("Telegram Credits")}</h2>
            </div>
          </div>
          <hr className="my-3" />
          <CreditPurchaseForm
            user={user}
            minimumCredits={100}
            creditType="telegram_credits"
          />
        </div>
      </div>

      <div className="col-xl-3 col-lg-6 col-sm-12 col-md-6 mb-3">
        <div className="card p-3">
          <div className="row">
            <div className="col-6 d-flex justify-content-center align-items-center">
              <FaWhatsapp color={"#0ca678"} size={60} />
            </div>
            <div className="col-6">
              <h2 className={"mt-2 mb-0"}>{creditData?.whatsapp_credits}</h2>
              <h2 className={"mb-2 mt-0"}>{t("WhatsApp Credits")}</h2>
            </div>
          </div>
          <hr className="my-3" />
          <CreditPurchaseForm
            user={user}
            minimumCredits={100}
            creditType="whatsapp_credits"
          />
        </div>
      </div>
    </div>
  );
}

export default CreditPurchase;
