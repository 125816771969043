import React, { useEffect, useState } from "react";
import { useLanguageStore } from "../../hooks/useLanguage";
import Modal from "react-bootstrap/Modal";
import {
  FaSave,
  FaTimes,
  FaUserPlus,
  FaTrash,
  FaPencilAlt,
  FaPlus,
} from "react-icons/fa";
import { getCookie } from "../../libs/utils";
import toast from "react-hot-toast";
import SearchField, { useSearch } from "../../components/SearchField";

const glowShadowStyle = {
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
};

const top_glowShadowStyle = {
  boxShadow: "0 -6px 5px 0px rgba(0, 0, 0, 0.3)",
};

export const RealModal = (props) => {
  const { t } = useLanguageStore();
  const {
    onHide,
    theme,
    incomeTypeToEdit,
    incomeTypeRemainingFunds,
    handleShowAddIndependentFundModal,
    setIncomeTypeToEdit,
  } = props;
  const { results, setSearchTerm, searchTerm, setResults } = useSearch();
  const [fundPercentage, setFundPercentage] = useState(100);
  const [selectedFund, setSelectedFund] = useState(null);

  const selectedFundChanged = (fund) => {
    setSelectedFund(fund);
  };

  const deselectFund = () => {
    setSelectedFund(null);
  };

  const handleSaveClicked = () => {
    setIncomeTypeToEdit({
      ...incomeTypeToEdit,
      independent_funds: [
        ...incomeTypeToEdit.independent_funds,
        {
          ...selectedFund,
          percentage: fundPercentage,
        },
      ],
    });
    onHide();
  };

  const handleSliderChange = (e) => {
    const newValue = Number(e.target.value);
    setFundPercentage(newValue);
  };

  return (
    <>
      <Modal.Body className={`p-0 box-shadow-${theme} rounded-3`}>
        <div className="row p-3 pb-1">
          <div className="d-flex col-lg-12">
            <h3 className={"mb-0"}>
              {t("Add Fund to")} {incomeTypeToEdit?.name}
            </h3>
            <FaTimes
              size={20}
              className={"ms-auto cursor-pointer"}
              onClick={onHide}
            />
          </div>
          <div className={"d-flex justify-items-center align-items-center"}>
            <span
              onClick={handleShowAddIndependentFundModal}
              className={
                "mt-2 badge badge-pill bg-primary-lt p-2 d-flex cursor-pointer"
              }
            >
              <FaPlus />
              <span className={"ms-1"}>{t("Add New Fund")}</span>
            </span>
          </div>

          <div className="col-6 mt-3">
            <div className="form-group">
              <label htmlFor="fundPercentage">Percentage</label>
              {/* <input type="number" onInput={percentageChanged} value={fundPercentage} className="form-control"
                id="fundPercentage"
                placeholder="Enter percentage" /> */}

              <input
                type="range"
                id="fundPercentageSlider"
                className="form-range"
                min="0"
                max="100"
                step="5"
                value={fundPercentage}
                onChange={(e) => handleSliderChange(e)}
              />
            </div>
          </div>
        </div>
        <div className="row p-3 pb-0 pt-2">
          {!selectedFund && (
            <div className="col-lg-6 mb-3">
              <div className={"form-group"}>
                <SearchField
                  placeholder={t("Search Funds")}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  setResults={setResults}
                  options={incomeTypeRemainingFunds}
                  dictKey={"name"}
                />
              </div>
            </div>
          )}
        </div>
        <div className="pt-0 row">
          {!selectedFund && (
            <div className="col-12 table-responsive">
              <table className="table table-hover table-striped">
                <tbody>
                  {results?.map((fund, fundIndex) => {
                    return (
                      <tr
                        key={fundIndex}
                        onClick={() => selectedFundChanged(fund)}
                      >
                        <td>{fund?.name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {selectedFund && (
            <div className="col-12 mt-2">
              <div
                className="card rounded-top-0 rounded-bottom-3 p-2"
                style={top_glowShadowStyle}
              >
                <h5 className="m-0 h4 text-center">{selectedFund?.name}</h5>
                <div className={"mt-1 rounded text-center"}>
                  <span className={"badge h3 bg-success-lt p-2 mb-0"}>
                    {fundPercentage}%
                  </span>
                  {/*<span className={"badge bg-danger-lt p-2 ms-2 cursor-pointer"} onClick={deselectFund}>*/}
                  {/*  <FaTrash/>*/}
                  {/*</span>*/}
                </div>
                <div className="row mt-3">
                  <div className="col-8">
                    <button
                      onClick={handleSaveClicked}
                      className="btn btn-primary w-100"
                    >
                      <FaSave className={"me-2"} />
                      {t("Save")}
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={deselectFund}
                    >
                      <FaTrash className={"me-2"} />
                      {t("Cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </>
  );
};

export const AddFundToIncomeTypeModal = (props) => {
  let { show, onHide, theme } = props;
  return (
    <Modal
      size={"md"}
      show={show}
      onHide={onHide}
      className={`modal-custom-backdrop-${theme}`}
      backdrop={"static"}
    >
      {show && <RealModal {...props} />}
    </Modal>
  );
};
