import React, {useEffect, useState} from 'react';
import { useLanguageStore } from "../../hooks/useLanguage";
import Modal from 'react-bootstrap/Modal';
import {FaTimes, FaSave} from "react-icons/fa";
import toast from "react-hot-toast";

const types = [
    "Child",
    "Adult",
    "Senior",
]
const ftypes = [
    "Head",
    "Spouse",
    "Child",
]

export const FamilyMemberModal = (props) => {
    const { t } = useLanguageStore();
    const {
        show,
        onHide,
        members,
        setMembers,
        selectedMember,
        setSelectedMember,
    } = props;

    const onCancel = () => {
        // remove member from list
        let new_members = members.filter((member) => {
            return member.id !== selectedMember.id;
        });
        setMembers(new_members);
        onHide();
    }

    const valueChanged = (e) => {
        setSelectedMember({
            ...selectedMember,
            [e.target.name]: e.target.value
        });
    }

    const onSave = () => {
        // check if members already has a member with ftype === "Head" or "Spouse"
        let head = members.find((member) => member?.ftype === "Head");
        let spouse = members.find((member) => member?.ftype === "Spouse");
        if(selectedMember.ftype === "Head" && head) {
            toast.error(t("Head already exists"));
            return;
        }
        if(selectedMember.ftype === "Spouse" && spouse) {
            toast.error(t("Spouse already exists"));
            return;
        }
        let new_members = members.map((member) => {

            if(member.id === selectedMember.id){
                return selectedMember;
            }
            return member;
        });
        setMembers(new_members);
        onHide();
    }

    return (
        <>

            <Modal
                size={'lg'}
                show={show}
                onHide={onCancel}
                centered={true}
                className={"modal-blur"}
            >
                <Modal.Body>

                    <div className="row">
                        <div className="d-flex col-lg-12">
                            <h3>
                                {t("Edit Member")}
                            </h3>
                            <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onCancel}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 mb-2">
                            <div className="form-group">
                                <label htmlFor="first_name">{t("First Name")}</label>
                                <input type="text" name="first_name" value={selectedMember?.first_name}
                                       onChange={valueChanged} className="form-control"/>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                            <div className="form-group">
                                <label htmlFor="last_name">{t("Last Name")}</label>
                                <input type="text" name="last_name" value={selectedMember?.last_name}
                                       onChange={valueChanged} className="form-control"/>
                            </div>
                        </div>

                        <div className="col-lg-6 mb-2">
                            <div className="form-group">
                                <label htmlFor="last_name">{t("Date of Birth")}</label>
                                <input type="date" name="dob" value={selectedMember?.dob}
                                       onChange={valueChanged} className="form-control"/>
                            </div>
                        </div>

                        {/*<div className="col-lg-6 mb-2">*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label htmlFor="type">Type</label>*/}
                        {/*        <select name="type" value={selectedMember?.type} onChange={valueChanged} className="form-control">*/}
                        {/*            {types.map((type, index) => {*/}
                        {/*                return (*/}
                        {/*                    <option key={index} value={type}>{type}</option>*/}
                        {/*                )*/}
                        {/*            })}*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="col-lg-6 mb-2">
                            <div className="form-group">
                                <label htmlFor="ftype">{t("Family Member Type")}</label>
                                <select name="ftype" value={selectedMember?.ftype} onChange={valueChanged} className="form-control">
                                    {ftypes.map((type, index) => {
                                        return (
                                            <option key={index} value={type}>{t(type)}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>



                    <div className="row mt-2">
                        <div className="col-lg-12">
                            <button className={"btn btn-outline-primary"} onClick={onSave}>
                                <FaSave size={20} className={"me-2"}/>
                                {t("Save")}
                            </button>
                        </div>
                    </div>
                        </div>

                </Modal.Body>
            </Modal>
        </>
    );
}