import React, { useEffect, useState } from "react";
import { useLanguageStore } from "../hooks/useLanguage";
import { useAuth } from "../hooks/useAuth";
import "../styles/Custom.css";
import SelectInput from "../components/CustomSelect";
import { useIncome } from "../hooks/useIncome";
import { AddIndependentFundModal } from "../components/modals/AddIndependentFundModal";
import { useTheme } from "../hooks/useTheme";
import { FaMousePointer, FaPlus, FaPencilAlt } from "react-icons/fa";
import { EditIndependentFundModal } from "../components/modals/EditIndependentFundModal";
import { IncomeTypeList } from "../components/IncomeTypeList";
import { EditIncomeTypeModal } from "../components/modals/EditIncomeTypeModal";
import { AddFundToIncomeTypeModal } from "../components/modals/AddFundToIncomeTypeModal";
import { Grid } from "@progress/kendo-react-grid";

export const Expenses = (props) => {
  const { t } = useLanguageStore();
  const { user } = useAuth();
  const [showManageExpenseModal, setShowManageExpenseModal] = useState(false);
  const [expenses, setExpenses] = useState([]);

  const { theme } = useTheme();
  const {
    accounts,
    selectedAccount,
    getAccounts,
    setSelectedAccount,
    getIncomeTypes,
  } = useIncome();

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      getExpenses(selectedAccount);
      getIncomeTypes(selectedAccount);
    }
  }, [selectedAccount]);

  const getExpenses = (account) => {
    fetch(`/apiv2/expenses/${account?.id}/`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.success) {
          setExpenses(data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShowManageExpenseModal = () => {
    setShowManageExpenseModal(true);
  };

  return (
    <>
      <div className="container-fluid">
        <div className={"d-flex justify-items-center align-items-center mb-2"}>
          <h2 className={"m-0"}>{t("Expense Setup")}</h2>
        </div>
        <div className="row">
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <SelectInput
                options={accounts}
                dictKey={"id"}
                dictDescription={"name"}
                search={true}
                placeholder={t("Select Account")}
                valueChanged={setSelectedAccount}
                initialValue={selectedAccount}
                forcedValue={selectedAccount}
              />
            </div>
          </div>
        </div>

        <div className="w-100 mt-2">
          <div className="w-100 overflow-auto d-flex row flex-nowrap ps-1">
            {selectedAccount && (
              <>
                <span
                  onClick={handleShowManageExpenseModal}
                  className="badge badge-pill me-1 h4 d-flex bg-primary-lt justify-items-center align-items-center cursor-pointer w-fit-content"
                >
                  <FaPencilAlt className={"me-1"} />
                  {t("Manage Expense")}
                </span>
              </>
            )}
          </div>
        </div>

        {selectedAccount && (
          <Grid data={expenses} style={{ height: "400px" }}>
            <Grid field="name" title="Name" />
            <Grid field="amount" title="Amount" />
            <Grid field="date" title="Date" />
          </Grid>
        )}
      </div>
    </>
  );
};
