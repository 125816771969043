import React, { useEffect, useRef, useState } from "react";
import { useLanguageStore } from "../hooks/useLanguage";
import { useAuth } from "../hooks/useAuth";
import { useTheme } from "../hooks/useTheme";
import toast from "react-hot-toast";
import { Groups } from "./Groups";
import { Button } from "@progress/kendo-react-buttons";

const darkBg = {
  backgroundColor: "#151f2c",
};

export const OtherSettings = (props) => {
  const { t } = useLanguageStore();
  const { user } = useAuth();
  const { theme } = useTheme();
  const [showGroupModal, setShowGroupModal] = useState(false);

  const handleShowGroupsModal = () => {
    setShowGroupModal(true);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Groups
            showGroupModal={showGroupModal}
            setShowGroupModal={setShowGroupModal}
            handleShowGroupsModal={handleShowGroupsModal}
            user={user}
            theme={theme}
          />
        </div>
      </div>
    </>
  );
};
