export const desktopMediaQuery = "(min-width: 1024px)";
export const tabletMediaQuery = "(min-width: 768px)";
export const mobileMediaQuery = "(max-width: 640px)";

export const ROUTES = {
  home: "/",
  members: "/members",
  census: "/census",
  settings: "/settings",
  company: "/settings/company",
  professions: "/settings/professions",
  groups: "/settings/groups",
  messaging: "/settings/messaging",
  usermanagement: "/settings/usermanagement",
  database: "/settings/database",
  reminders: "/reminders",
  birthdays: "/reminders/birthdays",
  events: "/reminders/events",
};
