import * as React from "react";
import { useLanguageStore } from "../../hooks/useLanguage";
import { useAuth } from "../../hooks/useAuth";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { getCookie } from "../../libs/utils";
import { Button } from "@progress/kendo-react-buttons";
import toast from "react-hot-toast";
import { FaTimes } from "react-icons/fa";
import "../../styles/changepasswordmodal.css";
import { Upload, UploadFileStatus } from "@progress/kendo-react-upload";
import { loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";

export const ChangePictureModal = (props) => {
  const {
    handleCloseChangePicture,
    closeChangePictureModal,
    theme,
    user,
    auth,
  } = props;

  const { t } = useLanguageStore();
  const { setUser } = useAuth();
  const requestIntervalRef = React.useRef({});
  const progressRef = React.useRef({});
  const [files, setFiles] = React.useState([]);
  const [error, setError] = React.useState(null);

  const changepicture = async (file) => {
    const formData = new FormData();
    formData.append("picture", file.getRawFile());
    try {
      const response = await fetch(`/apiv2/change_user_picture/`, {
        method: "POST",
        headers: {
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: formData,
      });
      const jsondata = await response.json();
      if (jsondata?.success === true) {
        toast.success(t("Picture Changed Successfully"));

        return true;
      } else {
        toast.error(t("Picture Change Failed"));
        return false;
      }
    } catch (error) {
      console.error(error);
      console.error(error);
      setError(error);
      toast.error(t("Picture Change Failed"));
      return false;
    }
  };

  const onModalHide = () => {
    handleCloseChangePicture();
  };

  loadMessages(
    {
      upload: {
        dropFilesHere: t("Drop photo here to upload"),
        select: t("Upload Photo"),
      },
    },
    "myCustomMessages"
  );

  const onSaveRequest = (files, _, onProgress) => {
    const uid = files[0].uid;
    progressRef.current[uid] = 0;

    const saveRequestPromise = new Promise((resolve) => {
      requestIntervalRef.current[uid] = setInterval(() => {
        onProgress(uid, {
          loaded: progressRef.current[uid],
          total: 100,
        });
        if (progressRef.current[uid] === 100) {
          resolve({
            uid: uid,
          });
          delete progressRef.current[uid];
          clearInterval(requestIntervalRef.current[uid]);
        } else {
          progressRef.current[uid] = progressRef.current[uid] + 1;
        }
      }, 40);
    });
    return saveRequestPromise;
  };

  const onCancel = (uid) => {
    // Simulate cancel custom request
    delete progressRef.current[uid];
    clearInterval(requestIntervalRef.current[uid]);
  };
  const onRemoveRequest = (files, _) => {
    const uid = files[0].uid;

    // Simulate remove request
    const removeRequestPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          uid: uid,
        });
      }, 300);
    });
    return removeRequestPromise;
  };

  const onAdd = async (event) => {
    const file = event.newState[0];
    setFiles([file]);
    await changepicture(file).then((response) => {
      if (response === true) {
        toast.success(t("Picture Changed Successfully"));
        handleCloseChangePicture();
        window.location.reload(true);
      } else {
        toast.error(t("Picture Change Failed"));
      }
    });
  };

  return (
    <div className="modal-overlay">
      <div
        className={
          theme == "dark" ? "modal-content-dark" : "modal-content-light"
        }
      >
        <div className="row">
          <div className="d-flex col-lg-12">
            <h3>{t("Change Picture")}</h3>
            <FaTimes
              size={20}
              className={"ms-auto cursor-pointer"}
              onClick={onModalHide}
            />
          </div>
        </div>
        <div>
          <LocalizationProvider language="myCustomMessages">
            <Upload
              batch={false}
              multiple={false}
              restrictions={{
                allowedExtensions: [".jpg", ".png", ".jpeg"],
              }}
              defaultFiles={[]}
              saveUrl={onSaveRequest}
              removeUrl={onRemoveRequest}
              onCancel={onCancel}
              withCredentials={false} // enable only for CORS
              files={files}
              onAdd={onAdd}
            />
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};
