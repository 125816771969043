import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// to be implemented
import { ROUTES } from "./libs/consts";

import { Home } from "./pages/Home";
import { Base } from "./components/Base";
import { Members } from "./pages/Members";
import { NotFound } from "./pages/NotFound";
import { Login } from "./pages/Login";
import { Settings } from "./pages/Settings";
import { ThemeProvider } from "./hooks/useTheme";
import { AuthProvider } from "./hooks/useAuth";
import { Groups } from "./pages/Groups";
import UserManagement from "./pages/UserManagement";
import { usePermissions } from "./hooks/PermissionContext";
import BirthdayReminder from "./pages/BirthdayReminder";
import UpComingEvents from "./pages/UpComingEvents";
import { CompanySetup } from "./pages/CompanySetup";
import { Messaging } from "./pages/Messaging";
import { ProfessionsPage } from "./pages/ProfessionsPage";
import { CensusPage } from "./pages/CensusPage";

import { BankStatement } from "./pages/BankStatement";
import { Reports } from "./pages/Reports";
import { Expenses } from "./pages/Expenses";
import { IncomeSetup } from "./pages/IncomeSetup";
import { Accounts } from "./pages/Accounts";
import { OtherSettings } from "./pages/OtherSettings";

export const App = () => {
  const permissions = usePermissions();
  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Base />}>
              <Route index element={<Home />} />
              <Route path="members" element={permissions?.permissions?.includes("view_members") && ( <Members /> ) } />
              <Route path="census" element={<CensusPage />} />
              <Route path="settings">
                <Route path="database" element={<Settings />} />
                <Route path="groups" element={<Groups />} />
                <Route path="usermanagement" element={<UserManagement />} />
                <Route path="company" element={<CompanySetup />} />
                <Route path="messaging" element={<Messaging />} />
                <Route path="professions" element={<ProfessionsPage />} />
              </Route>
              <Route path="reminders/birthdays" element={<BirthdayReminder />} />
              <Route path="reminders/events" element={<UpComingEvents />} />
              <Route path="accounting" element={<Settings />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};
