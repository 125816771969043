import React, { useEffect, useState } from "react";
import { useLanguageStore } from "../hooks/useLanguage";
import { useEnv } from "../hooks/useEnv";
import { useAuth } from "../hooks/useAuth";
import { useWebsocket } from "../hooks/useWebsocket";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../hooks/useTheme";
import { usePermissions } from "../hooks/PermissionContext";
import "../styles/Custom.css";
import { fetchGetMessageCredits } from "../libs/api";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CreditPurchase from "../components/CreditPurchase";
import SendMessage from "../components/SendMessage";
import MessageHistory from "../components/MessageHistory";

export const Messaging = (props) => {
  const { t } = useLanguageStore();
  const permissions = usePermissions();
  const navigate = useNavigate();
  const { user } = useAuth();
  // const {ws_send, ws_status, message, initWebsocket, ws_connected} = useWebsocket(env);
  const { theme } = useTheme();
  const [creditData, setCreditData] = useState({});

  useEffect(() => {
    getMessageCredits();
  }, []);

  const getMessageCredits = () => {
    fetchGetMessageCredits()
      .then((all) => {
        return all?.data;
      })
      .then((data) => {
        setCreditData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="container-fluid">
        {/* <div className={"d-flex justify-items-center align-items-center mb-3"}>
          <h2 className={"m-0"}>
            {t("Messaging")}
          </h2>
        </div> */}

        <Tab.Container defaultActiveKey="credits">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="credits">{t("Credits")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="send-message">{t("Send Message")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="message-history">
                {t("Message History")}
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content className="mt-3">
            <Tab.Pane eventKey="credits">
              {/* <h4>Home Content</h4>
              <p>Welcome to the home tab!</p> */}
              <CreditPurchase user={user} creditData={creditData} />
            </Tab.Pane>
            <Tab.Pane eventKey="send-message">
              <SendMessage user={user} creditData={creditData} />
            </Tab.Pane>
            <Tab.Pane eventKey="message-history">
              <MessageHistory user={user} creditData={creditData} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};
