import React, { useState, useEffect, Fragment } from "react";
import { useLanguageStore } from "../hooks/useLanguage";
import { getCookie } from "../libs/utils";
import { FaEnvelope, FaRegMessage } from "react-icons/fa6";
import { BirthdayMessageModal } from "../components/modals/BirthdayMessageModal";
import { MessagesSent } from "../components/modals/MessagesSent";
import { AddFamilyModal } from "../components/modals/AddFamilyModal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "react-bootstrap";
import { usePermissions } from "../hooks/PermissionContext";
import { useTheme } from "../hooks/useTheme";

const BirthdayReminder = (user = { user }) => {
  const permissions = usePermissions();
  const { theme, toggleTheme } = useTheme();
  const { t } = useLanguageStore();
  const [isLoaded, setIsLoaded] = useState(false);
  const [birthdays, setBirthdays] = useState([]);
  const [showbirthdaymsgmodal, setShowbirthdaymsgmodal] = useState(false);
  const [selectedbirthday, setSelectedbirthday] = useState(undefined);
  const [selectedbirthdayid, setSelectedbirthdayid] = useState(undefined);
  const [showMessageSentModal, setShowMessageSentModal] = useState(false);

  useEffect(() => {
    getBirthdays();
  }, []);

  const getBirthdays_api = (company_id) => {
    return new Promise((resolve, reject) => {
      fetch(`/apiv2/get_birthdays`, {})
        .then((res) => res.json())
        // .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const getBirthdays = () => {
    getBirthdays_api()
      .then((data) => {
        setBirthdays(data);
        setIsLoaded(true);
      })
      .catch((err) => {
        alert(err);
      });
  };
  const handleShowBirthdayMessageModal = () => {
    setShowbirthdaymsgmodal(true);
  };

  const handleCloseBirthdayMessageModal = () => {
    setShowbirthdaymsgmodal(false);
  };
  const handleShowMessagesSentModal = (e, bd) => {
    setShowMessageSentModal(true);
    setSelectedbirthdayid(function (prevState) {
      var newMsg = bd.id;

      return newMsg;
    });
  };

  const handleCloseMessagesSentModal = () => {
    setShowMessageSentModal(false);
  };

  const sendemail = (e, bd) => {
    setSelectedbirthday(function (prevState) {
      var newBd = bd;
      setShowbirthdaymsgmodal(true);
      return newBd;
    });
  };

  return (
    <div>
      {!isLoaded && (
        <div className={`card-overlay-${theme}`}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">{t("Loading...")}</span>
          </div>
        </div>
      )}
      {isLoaded && (
        <>
          <h1 className="px-3">{t("Upcoming Birthdays")}</h1>
          <div style={{ overflow: "auto" }}>
            <table
              className="table table-hover table-striped mb-0"
              style={{ width: "100%", borderCollapse: "collapse" }}
            >
              <thead>
                <tr>
                  <th style={{ flex: 1, textAlign: "left", padding: "8px" }}>
                    {t("Name")}
                  </th>
                  <th style={{ flex: 1, textAlign: "left", padding: "8px" }}>
                    {t("Upcoming Birthday")}
                  </th>
                  <th style={{ flex: 0.5, textAlign: "left", padding: "8px" }}>
                    {t("Age")}
                  </th>
                  {permissions?.permissions?.includes("send_messages") && (
                    <th style={{ flex: 0.5, textAlign: "left" }}>
                      {t("Message")}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {birthdays.map((bd, index) => {
                  let even_bg = index % 2 === 0 ? "rgba(56,73,94,0.71)" : "";
                  return (
                    <tr
                      key={index}
                      className={theme === "dark" ? "dark" : "light"}
                      style={{
                        // backgroundColor: even_bg,
                        justifyContent: "space-between",
                      }}
                    >
                      <OverlayTrigger
                        placement="top"
                        style={{ cursor: "pointer" }}
                        overlay={
                          <Tooltip id={"tooltip-top"}>
                            {permissions?.permissions?.includes("view_messages")
                              ? t("See Messages Sent")
                              : t("No permission to view Messages Sent")}
                          </Tooltip>
                        }
                      >
                        <td
                          onClick={(e) => handleShowMessagesSentModal(e, bd)}
                          style={{ cursor: "pointer" }}
                        >
                          {bd.Names} {bd.Surname}{" "}
                        </td>
                      </OverlayTrigger>
                      <td onClick={(e) => handleShowMessagesSentModal(e, bd)}>
                        {bd.next_birthday}
                      </td>
                      <td onClick={(e) => handleShowMessagesSentModal(e, bd)}>
                        {bd.age}
                      </td>

                      {permissions?.permissions?.includes("send_messages") && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={"tooltip-top"}>
                              {t("Send a Birthday Message")}
                            </Tooltip>
                          }
                        >
                          <td
                            className="text-center"
                            onClick={(e) => sendemail(e, bd)}
                            style={{ cursor: "pointer" }}
                          >
                            <FaEnvelope color={"#0ca678"} size={18} />
                          </td>
                        </OverlayTrigger>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {selectedbirthday &&
            permissions?.permissions?.includes("send_messages") && (
              <BirthdayMessageModal
                selectedbirthday={selectedbirthday}
                show={showbirthdaymsgmodal}
                onShow={handleShowBirthdayMessageModal}
                onHide={handleCloseBirthdayMessageModal}
                user={user}
              />
            )}
          {permissions?.permissions?.includes("view_messages") && (
            <MessagesSent
              show={showMessageSentModal}
              onShow={handleShowMessagesSentModal}
              onHide={handleCloseMessagesSentModal}
              selectedbirthdayid={selectedbirthdayid}
            />
          )}
        </>
      )}
    </div>
  );
};
export default BirthdayReminder;
