import React, {useEffect, useState} from "react";
import {useLanguageStore} from "../../hooks/useLanguage";
import toast from "react-hot-toast";
import {Modal} from "react-bootstrap/";
import {FaTimes} from "react-icons/fa";
import {fetchAddVisit, fetchVisitReasons, fetchStaff} from "../../libs/api";
import "@progress/kendo-theme-default/dist/all.css"; // Ensure Kendo UI CSS is included

const DEFAULT_VISIT = {
    visit_date: "",
    reason: 2, // default visit reason (general visit)
    visit_notes: "",
    visiting_user: null,
    visited_user: null,
};

export const AddVisitModal = (props) => {
    const {t} = useLanguageStore();
    const {showVisit, onHideVisit, theme, selectedMember, user} = props;

    const [visit, setVisit] = useState(DEFAULT_VISIT);
    const [staff, setStaff] = useState([]);
    const [reasons, setReasons] = useState([]);

    useEffect(() => {
        getStaff();
        getReasons();
        setVisit((prev) => ({
            ...prev,
            visiting_user: null,
            visited_user: selectedMember,
        }));
    }, [props.show, selectedMember, user]);

    const handleVisit = (e) => {
        try {
            fetchAddVisit(visit)
                .then((data) => {
                    onHideVisit();
                })
                .catch((err) => {
                    toast.error(err);
                });
        } catch (error) {
            toast.error(error);
        }
    };

    const getReasons = () => {
        fetchVisitReasons()
            .then((data) => {
                setReasons(data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getStaff = () => {
        fetchStaff()
            .then((data) => {
                setStaff(data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const visitValueChanged = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "staff" && !isNaN(value)) {
            value = Number(value);
        }

        setVisit((prev) => ({...prev, visiting_user: value}));
    };
    const visitReasonChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === "reason" && !isNaN(value)) {
            value = Number(value);
        }

        setVisit((prev) => ({...prev, reason: value}));
    };

    return (
        <>
            {showVisit && (
                <Modal
                    size={"lg"}
                    show={showVisit}
                    onHide={onHideVisit}
                    centered={true}
                    className={`modal-custom-backdrop-${theme}`}
                    backdrop={"static"}
                >
                    <Modal.Body className={`box-shadow-${theme} rounded-3`}>
                        <div className="row">
                            <div className="d-flex col-lg-12">
                                <h3>{t("Add Visit")}</h3>
                                <FaTimes
                                    size={20}
                                    className={"ms-auto cursor-pointer"}
                                    onClick={onHideVisit}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <h4 className={"text-center p-1 bg-primary-lt mb-0"}>
                                        {t("Visit Information")}
                                    </h4>
                                    <div className="row p-3">
                                        <div className="col-lg-12">
                                            <section className="modal-card-body">
                                                <form onSubmit={handleVisit}>
                                                    <div style={{margin: 5}}>
                                                        <label>{t("Visited by")}</label>
                                                        <select
                                                            onChange={visitValueChanged}
                                                            name={"staff"}
                                                            className="form-control"
                                                            value={visit.visiting_user || ""}
                                                        >
                                                            <option value="" disabled>
                                                                {t("...Select Visited By")}
                                                            </option>
                                                            {staff.map((st, index) => {
                                                                return (
                                                                    <option key={index} value={st.id}>
                                                                        {st.username}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>

                                                    <div style={{margin: 5}}>
                                                        <label>{t("Visit Reason")}</label>
                                                        <div>
                                                            <select
                                                                onChange={visitReasonChange}
                                                                name={"reason"}
                                                                className="form-control"
                                                                value={visit.reason || ""}
                                                            >
                                                                {reasons.map((reason, index) => {
                                                                    return (
                                                                        <option key={index} value={reason.id}>
                                                                            {reason.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div style={{margin: 5}}>
                                                        <label>{t("Visit Date")}</label>
                                                        <div>
                                                            <input
                                                                className="form-control"
                                                                type="date"
                                                                placeholder={t("Visit Date")}
                                                                value={visit.visit_date}
                                                                onChange={(e) =>
                                                                    setVisit({
                                                                        ...visit,
                                                                        visit_date: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12" style={{margin: 5}}>
                                                        <label>{t("Visit Notes")}</label>
                                                        <div>
                                                          <textarea
                                                              className="form-control"
                                                              placeholder={t("Notes")}
                                                              value={visit.visit_notes}
                                                              rows={3}
                                                              onChange={(e) =>
                                                                  setVisit({
                                                                      ...visit,
                                                                      visit_notes: e.target.value,
                                                                  })
                                                              }
                                                          />
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                            <footer className="modal-card-foot">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={handleVisit}
                                                >
                                                    {t("Save")}
                                                </button>
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    onClick={onHideVisit}
                                                >
                                                    {t("Cancel")}
                                                </button>
                                            </footer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};
