import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavModal } from "./modals/NavModal";
import { useTheme } from "../hooks/useTheme";
import { useAuth } from "../hooks/useAuth";
// import LanguageSwitcher from "./LanguageSwitcher";
import { usePermissions } from "../hooks/PermissionContext";
import { ChangePasswordModal } from "./modals/ChangePasswordModal";
import { ChangePictureModal } from "./modals/ChangePictureModal";
import { useLanguageStore, languages } from "../hooks/useLanguage";
import {
  Navbar,
  NavDropdown,
  Container,
  Nav,
  Offcanvas,
} from "react-bootstrap";
import { FaGlobe, FaImage, FaUserLock } from "react-icons/fa";
import { LuAlarmClock } from "react-icons/lu";
import {
  FaHouse,
  FaGears,
  FaUser,
  FaUsers,
  FaMoon,
  FaSun,
  FaLock,
  FaMoneyBill,
  FaMoneyBills,
} from "react-icons/fa6";
import { ROUTES } from "../libs/consts";

// New component for navigation items
const NavigationItems = ({
  permissions,
  t,
  accountingUrl,
  isOffcanvas,
  closeOffcanvas,
}) => (
  <Nav className={`me-auto ${isOffcanvas ? "flex-column" : ""}`}>
    <Nav.Link as="div" onClick={closeOffcanvas}>
      <Link
        to={ROUTES.home}
        className="text-decoration-none text-reset d-flex flex-row align-items-center"
      >
        <FaHouse className="me-1" />
        {t("Home")}
      </Link>
    </Nav.Link>

    {permissions?.permissions?.includes("view_members") && (
      <Nav.Link as="div" onClick={closeOffcanvas}>
        <Link
          to={ROUTES.members}
          className="text-decoration-none text-reset d-flex flex-row align-items-center"
        >
          <FaUsers />
          <span className="ms-1">{t("Members")}</span>
        </Link>
      </Nav.Link>
    )}

    {permissions?.permissions?.includes("view_transctn") && (
      <div className="nav-link">
        <a
          href={accountingUrl}
          // target="_blank"
          rel={"noopener noreferrer"}
          className="text-decoration-none text-reset d-flex flex-row align-items-center"
        >
          <FaMoneyBill />
          <span className="ms-1">{t("Finances")}</span>
        </a>
      </div>
    )}

    <Nav.Link as="div" onClick={closeOffcanvas}>
      <Link
        to={ROUTES.census}
        className="text-decoration-none text-reset d-flex flex-row align-items-center"
      >
        <FaUsers />
        <span className="ms-1">{t("Census")}</span>
      </Link>
    </Nav.Link>

    <NavDropdown
      title={
        <>
          <LuAlarmClock />
          <span className="ms-1">{t("Reminders")}</span>
        </>
      }
    >
      {permissions?.permissions?.includes("view_members") && (
        <NavDropdown.Item as="div" onClick={closeOffcanvas}>
          <Link
            to={ROUTES.birthdays}
            className="text-decoration-none text-reset"
          >
            {t("Upcoming Birthdays")}
          </Link>
        </NavDropdown.Item>
      )}
      <NavDropdown.Item as="div" onClick={closeOffcanvas}>
        <Link to={ROUTES.events} className="text-decoration-none text-reset">
          {t("Upcoming Events")}
        </Link>
      </NavDropdown.Item>
    </NavDropdown>

    <NavDropdown
      title={
        <>
          <FaGears />
          <span className="ms-1">{t("Settings")}</span>
        </>
      }
    >
      <NavDropdown.Item as="div" onClick={closeOffcanvas}>
        <Link to={ROUTES.company} className="text-decoration-none text-reset">
          {t("Company")}
        </Link>
      </NavDropdown.Item>
      <NavDropdown.Item as="div" onClick={closeOffcanvas}>
        <Link
          to={ROUTES.professions}
          className="text-decoration-none text-reset"
        >
          {t("Professions")}
        </Link>
      </NavDropdown.Item>
      <NavDropdown.Item as="div" onClick={closeOffcanvas}>
        <Link to={ROUTES.groups} className="text-decoration-none text-reset">
          {t("Groups")}
        </Link>
      </NavDropdown.Item>
      <NavDropdown.Item as="div" onClick={closeOffcanvas}>
        <Link to={ROUTES.messaging} className="text-decoration-none text-reset">
          {t("Messaging")}
        </Link>
      </NavDropdown.Item>
      <NavDropdown.Item as="div" onClick={closeOffcanvas}>
        <Link
          to={ROUTES.usermanagement}
          className="text-decoration-none text-reset"
        >
          {t("User Management")}
        </Link>
      </NavDropdown.Item>
      <NavDropdown.Item as="div" onClick={closeOffcanvas}>
        <Link to={ROUTES.database} className="text-decoration-none text-reset">
          {t("Database")}
        </Link>
      </NavDropdown.Item>
    </NavDropdown>
  </Nav>
);

export const TopNav = (props) => {
  const permissions = usePermissions();
  const { t, language, setLanguage } = useLanguageStore();
  const { theme, toggleTheme } = useTheme();
  const { user, signout } = useAuth();
  const navigate = useNavigate();

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showNavModal, setShowNavModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showChangePicture, setShowChangePicture] = useState(false);

  const accountingUrl = language
    ? `${window.location.origin}/acc?theme=${theme}&lang=${language.value}`
    : `${window.location.origin}/acc?theme=${theme}`;

  const toggleLanguage = () => {
    if (!language) return;
    const newLanguage = language.value === "en" ? languages.afr : languages.en;
    setLanguage(newLanguage);
  };

  return (
    <>
      <NavModal
        show={showNavModal}
        handleCloseNavModal={() => setShowNavModal(false)}
        handleShowNavModal={() => setShowNavModal(true)}
        theme={theme}
      />

      {showChangePasswordModal && (
        <ChangePasswordModal
          show={showChangePasswordModal}
          handleCloseChangePasswordModal={() =>
            setShowChangePasswordModal(false)
          }
          handleShowNavModal={() => setShowChangePasswordModal(true)}
          theme={theme}
        />
      )}

      {showChangePicture && (
        <ChangePictureModal
          show={showChangePicture}
          handleCloseChangePicture={() => setShowChangePicture(false)}
          handleShowChangePicture={() => setShowChangePicture(true)}
          theme={theme}
          user={user}
        />
      )}

      {/* Offcanvas Component for Responsive Menu */}
      <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link to={"/"} className="text-decoration-none text-reset brand">
              Admin<span className="text-primary">PRO</span>
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <NavigationItems
            permissions={permissions}
            t={t}
            accountingUrl={accountingUrl}
            isOffcanvas={true}
            closeOffcanvas={() => setShowOffcanvas(false)}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid={false}>
          <Navbar.Brand className="me-2 brand">
            <Link to={"/"} className="text-decoration-none text-reset">
              Admin<span className="text-primary">PRO</span>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setShowOffcanvas(true)} // Open Offcanvas for small screens
          />
          <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex">
            {/* Left Nav Items for larger screens */}
            <NavigationItems
              permissions={permissions}
              t={t}
              accountingUrl={accountingUrl}
              isOffcanvas={false}
            />

            {/* Right Nav Items */}
            <Nav className="">
              <Nav.Item
                onClick={toggleTheme}
                className="me-2"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                data-bs-original-title="Enable dark mode"
              >
                {theme === "light" ? <FaSun /> : <FaMoon />}
              </Nav.Item>

              <NavDropdown
                title={
                  <span className="nav-link d-flex lh-1 text-reset p-0">
                    {user?.picture ? (
                      <img
                        src={user.picture}
                        className="avatar avatar-sm"
                        alt="user"
                      />
                    ) : (
                      <FaUser className="avatar avatar-sm" />
                    )}
                    <div className="d-none d-xl-block ps-2">
                      <div>{user?.username}</div>
                    </div>
                  </span>
                }
              >
                <NavDropdown.Item onClick={signout}>
                  <FaLock className={"me-2"} />
                  {t("Logout")}
                </NavDropdown.Item>

                <NavDropdown.Item
                  onClick={() => setShowChangePasswordModal(true)}
                >
                  <FaUserLock className={"me-2"} />
                  {t("Change Password")}
                </NavDropdown.Item>

                <NavDropdown.Item onClick={() => setShowChangePicture(true)}>
                  <FaImage className={"me-2"} />
                  {user?.picture ? t("Change Picture") : t("Add Picture")}
                </NavDropdown.Item>

                <NavDropdown.Item onClick={toggleTheme}>
                  {theme === "light" ? (
                    <FaSun className={"me-2"} />
                  ) : (
                    <FaMoon className={"me-2"} />
                  )}
                  {theme === "light" ? t("Light Mode") : t("Dark Mode")}
                </NavDropdown.Item>

                <NavDropdown.Item onClick={toggleLanguage}>
                  <FaGlobe className={"me-2"} />
                  {language?.value === "en" ? t("Afrikaans") : t("English")}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
