import React, {useEffect, useState} from "react";
import {FaSave, FaTrash, FaUserPlus} from "react-icons/fa";
import {AddAdditionalFamilyMemberModal} from "./modals/AddAdditionalFamilyMemberModal";
import {getCookie} from "../libs/utils";
import {usePermissions} from "../hooks/PermissionContext";
import {Button} from "@progress/kendo-react-buttons";
import toast from "react-hot-toast";
import {useLanguageStore} from "../hooks/useLanguage";
import {Form} from "react-bootstrap";

export default function FamilyInfoTab(props) {
    const {t} = useLanguageStore();
    const permissions = usePermissions();
    const {
        show, onShow, onHide, user, selectedFamily, setSelectedFamily, theme, handleShowMemberModal, selectedMemberChanged, setSelectedMemberChanged, getFamily, getMembers,
    } = props;

    const [showMemberModal, setShowMemberModal] = useState(false);
    const [localSelectedMember, setLocalSelectedMember] = useState(null);
    const [family, setFamily] = useState(props.selectedFamily);
    const [selectedMember, setSelectedMember] = useState(null);
    const [members, setMembers] = useState([]);

    useEffect(() => {
        setFamily(props.selectedFamily);
    }, [props.selectedFamily]);

    useEffect(() => {
        if (showMemberModal) {
            getFamily();
        }
    }, [showMemberModal]);

    const MemberTypeDisplay = ({member}) => {
        if (member?.member_type === "Head") {
            return (<h4 className={"badge bg-success-lt mb-0 h4"}>
                {t(member?.member_type)}
            </h4>);
        } else if (member?.member_type === "Spouse") {
            return (<h4 className={"badge bg-warning-lt mb-0 h4"}>
                {t(member?.member_type)}
            </h4>);
        }
        return (<h4 className={"badge bg-info-lt mb-0 h4"}>{t(member?.member_type)}</h4>);
    };

    const memberClicked = (member) => {
        selectedMemberChanged(member);
        handleShowMemberModal();
    };

    const onMemberModalHide = () => {
        setShowMemberModal(false);
        getFamily();
    };

    const onAddMember = () => {
        let random_id = Math.floor(Math.random() * 1000000);
        let new_member = {
            id: random_id, first_name: "", last_name: "", dob: "", type: "Child", ftype: "Head", familyid: null,
        };
        setMembers([...members, new_member]);
        setSelectedMember(new_member);
        setShowMemberModal(true);
    };

    const handleDeleteFamily = (family_id) => {
        if (window.confirm("Are you sure you want to delete the entire Family?")) {
            deleteFamily_api(family_id).then((data) => {
                if (data?.success) {
                    toast.success(t("Family deleted successfully"));
                    getMembers();
                    onHide();
                    setSelectedFamily(null);
                } else {
                    toast.error(t("Failed to delete family"));
                }
            });
        }
    };

    const deleteFamily_api = async (family_id) => {
        const response = await fetch(`/apiv2/family/${family_id}/`, {
            method: "DELETE", headers: {
                "Content-Type": "application/json", "X-CSRFToken": getCookie("csrftoken"), family_id: family_id,
            },
        });
        return await response.json();
    };
    //   slice at each ; and render all of them underneath each other
    const renderAddress = (address) => {
        if (!address) return null;
        const addressParts = selectedFamily.suburb.split(";");
        return addressParts.map((part, index) => (<>
            <div className="col-4 fw-bold"></div>
            <div className="col-8" key={index}>
                {part}</div>
        </>));
    }

    // format suburb by replacing the ; with a , and remove blank spaces before the ; and add blank space after the ;
    const formatSuburb = (suburb) => {
        if (!suburb) return "";
        const parts = suburb.split(";");
        const formattedParts = parts.map((part) => part.trim());
        return formattedParts.join(", ");
    }

    // add uploaded  family home picture to the selectedFamily object
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedFamily((prevFamily) => ({
                    ...prevFamily,
                    housePicture: reader.result,
                }));
            };
            reader.readAsDataURL(file);
        }
    }
    console.log("selectedFamily", selectedFamily);

    return (
        <>
            {permissions?.permissions?.includes("delete_family") && (
                <div className="row" style={{paddingTop: "10px", paddingBottom: "10px"}}>
                    <div className="col-12 mt-2">
                        <div className="bg-primary-lt p-2 row">
                            {t("Family Address")}
                        </div>
                        <div className="container mt-3">
                            <div className="row">
                                {/* Left side: input fields */}
                                <div className="col-md-8">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-md-3">
                                            <label className="fw-bold">{t("Street Address")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="street_address"
                                                value={selectedFamily?.street_address}
                                                // onChange={valueChanged}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-md-3">
                                            <label className="fw-bold">{t("Area / Suburb")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="suburb"
                                                value={formatSuburb(selectedFamily?.suburb)}
                                                // onChange={valueChanged}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-md-3">
                                            <label className="fw-bold">{t("Postal Address")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="postal_address"
                                                value={selectedFamily?.street_address}
                                                // onChange={valueChanged}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-md-3">
                                            <label className="fw-bold">{t("Postal Code")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="postal_code"
                                                value={selectedFamily?.postal_code}
                                                // onChange={valueChanged}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-md-3">
                                            <label className="fw-bold">{t("Home Telephone nr")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="postal_code"
                                                value={selectedFamily?.members[0]?.phone_tel}
                                                // onChange={valueChanged}
                                            />
                                        </div>
                                    </div>

                                </div>

                                {/* Right side: image */}
                                <div className="col-md-4 d-flex flex-column justify-content-end align-items-center">
                                    <img
                                        src={selectedFamily?.housePicture ? selectedFamily.housePicture : "/download.jpg"}
                                        alt="Family"
                                        className="img-fluid rounded"
                                        style={{width: "150px", height: "150px", objectFit: "cover"}}
                                    />
                                    <Form.Group controlId="housePicture" className="mb-2">
                                        <Form.Label>{t("Upload Photo of your Home / Envirionment")}</Form.Label>
                                        {/*<Form.Control type="file" onChange={(e)=>console.log(e)}/>*/}
                                        <Form.Control type="file" onChange={handleFileChange}/>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
