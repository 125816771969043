import React from "react";
import {useLanguageStore} from "../../hooks/useLanguage";
import Modal from "react-bootstrap/Modal";
import FamilyTab from "../FamilyTab";
import {desktopMediaQuery} from "../../libs/consts";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import Tabs from "react-bootstrap/Tabs";
import FamilyInfoTab from "../FamilyInfoTab";

export const EditFamilyModal = (props) => {
    const {t} = useLanguageStore();
    const {show, onHide, selectedFamily, theme} = props;
    const isDesktop = useMediaQuery(desktopMediaQuery);

    return (
        <>
            <Modal
                size={"xl"}
                show={show}
                onHide={onHide}
                className={`modal-custom-backdrop-${theme}`}
                backdrop={"static"}
                fullscreen={!isDesktop}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("Family View")}{" "}
                        <span className={"badge bg-primary-lt ms-2"}>
              {selectedFamily?.members?.length} {t("Member(s)")}
            </span>
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="info" className="">
                        <Tabs.Item eventKey="info" title={t("Family Info")}>
                            {/*{t("Home Address")}*/}
                            <FamilyInfoTab {...props} setSelectedFamily={props.setSelectedFamily}/>
                        </Tabs.Item>
                        <Tabs.Item eventKey="members" title={t("Members")}>
                            <FamilyTab {...props} setSelectedFamily={props.setSelectedFamily}/>
                            {t("Family Members")}
                        </Tabs.Item>
                    </Tabs>
                    {/*<FamilyTab {...props} setSelectedFamily={props.setSelectedFamily}/>*/}
                </Modal.Body>
            </Modal>
        </>
    );
};
