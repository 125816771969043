import React, { useEffect, useState } from 'react';
import { FaCreditCard } from "react-icons/fa6";

export const CreditPurchaseForm = (props) => {
  const {
    creditType,
    minimumCredits,
    user
  } = props;
  const [transactionAmount, setTransactionAmount] = useState(0);
  const [productName, setProductName] = useState('');
  const [creditsToAdd, setCreditsToAdd] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [transactionFees, setTransactionFees] = useState(0);
  const pyamentMethods = [
    {
      name: 'ef',
      label: 'Instant EFT',
      // percentage: 0.02346
      percentage: 0.02346
    }, {
      name: 'cp',
      label: 'Capitec Pay',
      percentage: 0.02346
    },
  ]

  useEffect(() => {
    if (creditsToAdd && creditType && selectedPaymentMethod) {
      // email_credits
      // whatsapp_credits
      // telegram_credits
      // sms_credits
      let amount = 0;
      if (creditType == "email_credits") {
        // setTransactionAmount(creditsToAdd * 0.05);
        amount = creditsToAdd * 0.05;
        setProductName('Email Credits');
      } else if (creditType == "whatsapp_credits") {
        // setTransactionAmount(creditsToAdd * 0.10);
        amount = creditsToAdd * 0.10;
        setProductName('Whatsapp Credits');
      } else if (creditType == "telegram_credits") {
        // setTransactionAmount(creditsToAdd * 0.03);
        amount = creditsToAdd * 0.03;
        setProductName('Telegram Credits');
      } else if (creditType == "sms_credits") {
        // setTransactionAmount(creditsToAdd * 0.30);
        amount = creditsToAdd * 0.30;
        setProductName('SMS Credits');
      }

      if (selectedPaymentMethod && creditsToAdd > 0) {
        setTransactionAmount(amount + (amount * selectedPaymentMethod.percentage));
        setTransactionFees(amount * selectedPaymentMethod.percentage);
      } else {
        setTransactionAmount(0);
        setTransactionFees(0);
      }
    }
  }, [creditsToAdd, creditType, selectedPaymentMethod]);


  const creditValueChanged = (e) => {
    console.log(`'${e.target.value}'`);
    console.log(typeof e.target.value);
    if (e.target.value == '') {
      setCreditsToAdd(0);
    } else {
      setCreditsToAdd(e.target.value);
    }
  }

  const paymentMethodChanged = (e) => {
    setSelectedPaymentMethod(JSON.parse(e.target.value));
  }

  const canPurchase = () => {
    if ((creditsToAdd >= minimumCredits) && selectedPaymentMethod?.name) {
      return true;
    } else {
      return false;
    }
  }

  const Purchase = () => {
    let url = 'https://sandbox.payfast.co.za/eng/process';
    let formElement = document.createElement('form');
    let fields = [
      { name: 'merchant_id', value: '10011868' },
      { name: 'merchant_key', value: 'j3jayg5v1xt1o' },
      { name: 'amount', value: Math.round(transactionAmount * 100) / 100 },
      { name: 'item_name', value: `${creditsToAdd} ${productName}` },
      { name: 'payment_method', value: selectedPaymentMethod?.name },
      { name: 'name_first', value: user?.first_name },
      { name: 'name_last', value: user?.last_name },
      { name: 'email_address', value: user?.email },
      { name: 'custom_str1', value: Math.round(transactionAmount * 100) / 100 },
      { name: 'custom_str2', value: creditsToAdd },
      { name: 'custom_str3', value: creditType },
      { name: 'custom_int1', value: user?.id },
      { name: 'return_url', value: `${window.location.origin}${window.location.pathname}?type=success` },
      { name: 'notify_url', value: `${window.location.origin}/apiv2/credit_purchase_callback/` },
      { name: 'cancel_url', value: `${window.location.origin}${window.location.pathname}?type=cancel` },
    ]

    fields.forEach((field, fieldIndex) => {
      var newElement = document.createElement('input');
      newElement.setAttribute('type', 'hidden');
      newElement.setAttribute('name', field.name);
      newElement.setAttribute('value', field.value);
      formElement.appendChild(newElement);
      formElement.setAttribute('action', url);
      formElement.setAttribute('method', 'POST');
    });
    document.body.appendChild(formElement);
    formElement.submit();

  }


  return (
    <>
      <select onInput={paymentMethodChanged} className='form-control mb-2'>
        <option selected disabled value={null}>Select payment method</option>
        {pyamentMethods?.map((method, methodIndex) => {
          return (
            <option key={methodIndex} value={JSON.stringify(method)}>{method.label}</option>
          )
        })}
      </select>
      <div className={"d-flex"}>
        <input disabled={!selectedPaymentMethod?.name} onInput={creditValueChanged} id="email_credits" name="email_credits" type="number" className="form-control me-2" placeholder="Credits to purchase" />
        <button onClick={Purchase} disabled={!canPurchase()} className="btn btn-success" type="button">
          <FaCreditCard className='me-2' />
          Purchase
        </button>
      </div >
      <div className='mt-2'>
        <table className='table'>
          <tbody>
            <tr>
              <td className='py-1 px-2'>Transaction</td>
              <td className='py-1 px-2'>R {creditsToAdd > 0 && Math.round(transactionFees * 100) / 100 || 0}</td>
            </tr>
            <tr>
              <td className='py-1 px-2'>Total amount</td>
              <td className='py-1 px-2'>R {creditsToAdd > 0 && Math.round(transactionAmount * 100) / 100 || 0}</td>
            </tr>
          </tbody>
        </table>
        {/* <span className='text-muted px-2'>Transaction: R {creditsToAdd > 0 && Math.round(transactionFees*100) / 100 || 0}</span>
          <span className='text-muted px-2'>Total amount: R {creditsToAdd > 0 && Math.round(transactionAmount*100) / 100 || 0}</span> */}
      </div>
    </>
  )

}