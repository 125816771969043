import React, { useState, useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import { PageHeader } from "../components/PageHeader";

// we should somehow support arbitrary census types. also perhaps need to keep a census table to avoid redundant calculations
const censusTypes = [
  {
    name: "joined_date",
    label: "Joined on date",
  },
  {
    name: "total_users",
    label: "Total Members",
  },
  {
    name: "total_children",
    label: "Total Children",
  },
  {
    name: "ages_19_to_30",
    label: "Members aged 19-30",
  },
];

export function CensusPage() {
  const [censusData, setCensusData] = useState([]);

  useEffect(() => {
    fetch("/apiv2/census/")
      .then((res) => res.json())
      .then((data) => {
        setCensusData(data.census);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container>
      <PageHeader title="Census" />
      <div className="card card-table">
        <Table hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {censusTypes.map((censusType) => {
              const censusValue = censusData[censusType.name];
              return (
                <tr key={censusType.name}>
                  <td>{censusType.label}</td>
                  <td>{censusValue}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Container>
  );
}
