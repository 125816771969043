import { Stack } from "react-bootstrap";
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

export function PageHeader({ title, children, back = false }) {
  const navigate = useNavigate();

  return (
    <Stack direction="horizontal" gap={3} className="mb-3">
      {back && (
        <button
          onClick={() => navigate(-1)}
          style={{
            background: "none",
            border: "none",
            padding: 0,
            cursor: "pointer",
          }}
        >
          <IconArrowLeft />
        </button>
      )}
      <h2 className="mb-0">{title}</h2>
      <div className="ms-auto">{children}</div>
    </Stack>
  );
}
