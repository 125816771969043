import {useState, useEffect} from "react";
import {useLanguageStore} from "../hooks/useLanguage";
import {FaSearch, FaCog, FaPhone, FaIdCard, FaList, FaTable, FaBars} from "react-icons/fa";
import {SearchModal} from "./modals/SearchModal";
import {Paginator} from "./Paginator";
import {Table, Dropdown, ButtonGroup, Button, Card, Row, Col, ListGroup} from "react-bootstrap";
import TableHeadColumn from "./TableHeadColumn";
import {useMediaQuery} from "../hooks/useMediaQuery";
import {desktopMediaQuery} from "../libs/consts";

const COLUMNS = [
    {text: "Lid #", keyName: "UniqueLidNo"},
    {text: "Names", keyName: "Names"},
    {text: "Full Names", keyName: "FullNames"},
    {text: "Surname", keyName: "Surname"},
    {text: "Family Information", keyName: "Family Information"},
    {text: "ID No", keyName: "IdNo"},
    {text: "Birth Date", keyName: "BirthDate"},
    {text: "Age", keyName: "age"},
    {text: "Cell Phone", keyName: "phone_cell"},
    {text: "Tel Phone", keyName: "phone_tel"},
    {text: "Type", keyName: "type"},
    {text: "Profession", keyName: "profession"},
    {text: "Home Address", keyName: "Home Address"},
    {text: "Date Updated", keyName: "date_updated"},
];

const TABLE_CONFIG = {
    striped: true,
    hover: true,
    size: "md",
};

export const MemberList = (props) => {
    const {
        showSearchModal,
        handleShowSearchModal,
        handleCloseSearchModal,
        rowClick,
        members,
        page,
        setPage,
        totalPages,
        searchChanged,
        search,
        theme,
        membersLoading,
        maxHeight,
        limit,
        setLimit,
        layout = "table",
        setLayout,
    } = props;

    const {t} = useLanguageStore();
    const isDesktop = useMediaQuery(desktopMediaQuery);

    const [tableConfig, setTableConfig] = useState(TABLE_CONFIG);
    const [columnConfig, setColumnConfig] = useState(
        COLUMNS.map((column) => ({
            ...column,
            hidden: false,
        }))
    );

    // Set default layout based on screen size if not explicitly set
    useEffect(() => {
        if (setLayout && !layout) {
            setLayout(isDesktop ? "table" : "grid");
        }
    }, [isDesktop, layout, setLayout]);

    const renderCell = (member, column) => {
        switch (column.keyName) {
            case "Family Information":
                return member?.HeadNo === 1
                    ? t("Head Member")
                    : member?.SpouseNo === 1
                        ? t("Spouse")
                        : t("Child");
            case "Home Address":
                return member?.home_address
                    ? `${member.home_address.street_address}, ${member.home_address.suburb}`
                    : "";
            default:
                return member?.[column.keyName] ?? "";
        }
    };

    const handleToggleColumn = (keyName) => {
        const newColumns = columnConfig.map((column) => {
            if (column.keyName === keyName) {
                return {...column, hidden: !column.hidden};
            }
            return column;
        });

        setColumnConfig(newColumns);
    };

    const handleTableConfigChange = (key) => {
        let value;
        if (key === "size") {
            value = tableConfig.size === "md" ? "sm" : "md";
        } else {
            value = !tableConfig[key];
        }
        setTableConfig({...tableConfig, [key]: value});
    };

    const renderTableLayout = () => (
        <Table
            striped={tableConfig.striped}
            hover={tableConfig.hover}
            size={tableConfig.size}
            className="table-nowrap"
            responsive
        >
            <thead>
            <tr>
                {columnConfig.map((column) => {
                    if (column.hidden) return null;
                    return (
                        <TableHeadColumn
                            key={column.keyName}
                            text={t(column.text)}
                            keyName={column.keyName}
                            {...props}
                        />
                    );
                })}
            </tr>
            </thead>
            <tbody>
            {members?.map((member, memberIndex) => (
                <tr key={memberIndex} onClick={(e) => rowClick(e, member)}>
                    {columnConfig.map((column) => {
                        if (column.hidden) return null;
                        return (
                            <td key={column.keyName}>{renderCell(member, column)}</td>
                        );
                    })}
                </tr>
            ))}
            </tbody>
        </Table>
    );

    const renderGridLayout = () => (
        <Row className="g-3">
            {members?.map((member, index) => (
                <Col key={index} xs={12} sm={6} md={4} lg={3}>
                    <Card
                        className="h-100 member-card"
                        onClick={(e) => rowClick(e, member)}
                    >
                        <Card.Body>
                            <Card.Title className="d-flex justify-content-between align-items-center">
                                <span>{member.Names} {member.Surname}</span>
                                <span className="badge bg-primary">{member.UniqueLidNo}</span>
                            </Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                                {member?.HeadNo === 1
                                    ? t("Head Member")
                                    : member?.SpouseNo === 1
                                        ? t("Spouse")
                                        : t("Child")}
                            </Card.Subtitle>
                            <div className="mt-3">
                                {member.phone_cell && (
                                    <div className="d-flex align-items-center mb-1">
                                        <FaPhone className="me-2 text-primary"/>
                                        <small>{member.phone_cell}</small>
                                    </div>
                                )}
                                {member.IdNo && (
                                    <div className="d-flex align-items-center mb-1">
                                        <FaIdCard className="me-2 text-primary"/>
                                        <small>{member.IdNo}</small>
                                    </div>
                                )}
                                {member.BirthDate && (
                                    <div className="d-flex align-items-center mb-1">
                                        <small>Age: {member.age}</small>
                                    </div>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    );

    const renderListLayout = () => (
        <ListGroup className="list-group-flush">
            {members?.map((member, index) => (
                <ListGroup.Item
                    key={index}
                    action
                    onClick={(e) => rowClick(e, member)}
                    className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center"
                >
                    <div className="mb-2 mb-sm-0">
                        <div className="fw-bold">{member.Names} {member.Surname}</div>
                        <div className="text-muted small">
                            {member?.HeadNo === 1
                                ? t("Head Member")
                                : member?.SpouseNo === 1
                                    ? t("Spouse")
                                    : t("Child")}
                            {member.IdNo && ` • ${member.IdNo}`}
                        </div>
                    </div>
                    {/* <div className="d-flex align-items-center">
            {member.phone_cell && (
              <div className="me-3">
                <FaPhone className="text-primary" />
              </div>
            )}
            <span className="badge bg-primary">{member.UniqueLidNo}</span>
          </div> */}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );

    const renderLayout = () => {
        switch (layout) {
            case 'grid':
                return renderGridLayout();
            case 'list':
                return renderListLayout();
            case 'table':
            default:
                return renderTableLayout();
        }
    };

    return (
        <>
            <SearchModal
                show={showSearchModal}
                handleCloseSearchModal={handleCloseSearchModal}
                handleShowSearchModal={handleShowSearchModal}
                searchChanged={searchChanged}
                memberSearch={search}
                theme={theme}
            />

            <Card className="p-0 m-0">
                {membersLoading && <LoadingOverlay {...{t, theme}} />}
                <Card.Header className="p-2 d-flex justify-content-between">
                    <div className={"d-flex align-items-center"}>

                    </div>
                    <div className={"d-flex flex-row align-items-center"}>
                        <TableSettingsButton
                            {...{
                                tableConfig,
                                columnConfig,
                                t,
                                handleToggleColumn,
                                handleTableConfigChange,
                                limit,
                                setLimit,
                                layout,
                            }}
                        />
                        <SearchBar
                            {...{searchChanged, search, t, handleShowSearchModal}}
                        />
                    </div>
                </Card.Header>
                <Card.Body
                    className="p-0 m-0"
                    style={{maxHeight: maxHeight ?? "70vh", overflowY: "auto"}}
                >
                    {renderLayout()}
                </Card.Body>
                <Card.Footer className="p-0">
                    <div>
                        <Paginator
                            page={page}
                            setPage={setPage}
                            totalPages={totalPages}
                            className={"btn btn-sm px-2 py-1"}
                            containerClassName={"mt-2"}
                        />
                        <div className="d-flex justify-content-center my-2">
              <span>
                {t("Page")} {page} {t("of")} {totalPages}
              </span>
                        </div>
                    </div>
                </Card.Footer>
            </Card>
        </>
    );
};

// this is also used elsewhere, therefore need to export and refactor
const LoadingOverlay = ({t, theme}) => (
    <div className={`card-overlay-${theme}`}>
        <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">{t("Loading...")}</span>
        </div>
    </div>
);

const TableSettingsButton = ({
                                 tableConfig,
                                 handleTableConfigChange,
                                 columnConfig,
                                 handleToggleColumn,
                                 t,
                                 limit,
                                 setLimit,
                                 layout,
                             }) => {
    return (
        <Dropdown autoClose="outside">
            <Dropdown.Toggle className="me-2" variant="ghost">
                <span className="d-none d-lg-inline-flex me-2">{t("Settings")}</span>
                <FaCog className=""/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <p className="ps-2 mb-1 mt-2 fs-6 fw-bolder text-secondary text-uppercase">
                    {t("Page Size")}
                </p>
                <ButtonGroup size="sm" className="my-2 px-2 w-100">
                    <Button
                        variant={limit === 5 ? "primary" : ""}
                        onClick={() => setLimit(5)}
                    >
                        5
                    </Button>
                    <Button
                        variant={limit === 10 ? "primary" : ""}
                        onClick={() => setLimit(10)}
                    >
                        10
                    </Button>
                    <Button
                        variant={limit === 20 ? "primary" : ""}
                        onClick={() => setLimit(20)}
                    >
                        20
                    </Button>
                </ButtonGroup>

                {layout === 'table' && (
                    <>
                        <p className="ps-2 mb-1 mt-2 fs-6 fw-bolder text-secondary text-uppercase">
                            {t("Table Layout")}
                        </p>

                        <ButtonGroup size="sm" className="my-2 px-2 w-100">
                            <Button
                                variant={tableConfig.striped ? "primary" : ""}
                                onClick={() => handleTableConfigChange("striped")}
                            >
                                {t("Striped")}
                            </Button>
                            <Button
                                variant={tableConfig.hover ? "primary" : ""}
                                onClick={() => handleTableConfigChange("hover")}
                            >
                                {t("Hover")}
                            </Button>
                            <Button
                                variant={tableConfig.size === "sm" ? "primary" : ""}
                                onClick={() => handleTableConfigChange("size")}
                            >
                                {t("Compact")}
                            </Button>
                        </ButtonGroup>

                        <p className="ps-2 mb-1 mt-2 fs-6 fw-bolder text-secondary text-uppercase">
                            {t("Columns")}
                        </p>
                        {columnConfig.map((column) => (
                            <Dropdown.Item
                                as="div"
                                className="d-flex align-items-center"
                                key={column.keyName}
                            >
                                <input type="checkbox"
                                       checked={!column.hidden}
                                       className="me-2"
                                       onChange={(e) => {
                                           e.stopPropagation(); // Prevent Dropdown.Item's onClick
                                           handleToggleColumn(column.keyName); // Update state
                                       }}
                                />
                                <span
                                    onClick={() => handleToggleColumn(column.keyName)}
                                    style={{cursor: "pointer"}}
                                >
                                    {t(column.text)}
                                </span>
                            </Dropdown.Item>
                        ))}
                    </>
                )}

            </Dropdown.Menu>
        </Dropdown>
    );
};

const SearchBar = ({searchChanged, search, t, handleShowSearchModal}) => {
    return (
        <>
            {/* Desktop View */}
            <div className="input-icon d-none d-lg-block">
                <input
                    type="text"
                    onInput={searchChanged}
                    value={search}
                    className="form-control"
                    placeholder={t("Search…")}
                />
                <span className="input-icon-addon">
          <FaSearch/>
        </span>
            </div>

            {/* Mobile View */}
            <div className="d-lg-none">
                <button onClick={handleShowSearchModal} className="btn btn-primary">
                    <FaSearch/>
                </button>
            </div>
        </>
    );
};
