import { create } from "zustand";
import translations from "../locales/translations.json";

export const languages = {
  en: {
    value: "en",
    symbol: "🇬🇧",
    label: "English",
    flag: "en",
  },
  afr: {
    value: "afr",
    symbol: "🇿🇦",
    label: "Afrikaans",
    flag: "afr",
  },
};

// Replace placeholder function
const replacePlaceholders = (str, params) => {
  return str.replace(/\${(\w+)}/g, (_, key) => params[key] || `\${${key}}`);
};

// Create Zustand store
export const useLanguageStore = create((set, get) => ({
  language: (() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      try {
        return JSON.parse(storedLanguage);
      } catch (e) {
        console.error("Error parsing language from localStorage", e);
        return languages.en; // Default to English if parsing fails
      }
    }
    return languages.en; // Default to English if not set
  })(),

  setLanguage: (newLanguage) => {
    set({ language: newLanguage });
    localStorage.setItem("language", JSON.stringify(newLanguage));
  },

  t: (phrase, params) => {
    const language = get().language;
     if (!language) {
      return phrase; // Return the phrase as is if language is not defined
    }
    const translated = translations[phrase]?.[language.value] || phrase;
    if (!translations[phrase]) {
      let missingPhrases = localStorage.getItem("missing-translations")
        ? JSON.parse(localStorage.getItem("missing-translations"))
        : {};
      missingPhrases[phrase] = {
        afr: "",
      };
      localStorage.setItem(
        "missing-translations",
        JSON.stringify(missingPhrases)
      );
    }
    return replacePlaceholders(translated, params);
  },

  languages: languages,
}));