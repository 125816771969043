// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* customStyles.css */
.k-input-solid-dark {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: rgba(21, 31, 44, 0.88);
}

.k-button-solid-base-dark {
    border-color: #3d3d3d;
    color:#3d3d3d;
    background-color: rgba(21, 31, 44, 0.88);
}


.k-button-solid-base-dark {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-base, #3d3d3d);
    background-color: rgba(21, 31, 44, 0.88);
}

`, "",{"version":3,"sources":["webpack://./src/styles/Reports.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,4DAA4D;IAC5D,iDAAiD;IACjD,wCAAwC;AAC5C;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,wCAAwC;AAC5C;;;AAGA;IACI,4DAA4D;IAC5D,0CAA0C;IAC1C,wCAAwC;AAC5C","sourcesContent":["/* customStyles.css */\n.k-input-solid-dark {\n    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));\n    color: var(--kendo-color-on-app-surface, #3d3d3d);\n    background-color: rgba(21, 31, 44, 0.88);\n}\n\n.k-button-solid-base-dark {\n    border-color: #3d3d3d;\n    color:#3d3d3d;\n    background-color: rgba(21, 31, 44, 0.88);\n}\n\n\n.k-button-solid-base-dark {\n    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));\n    color: var(--kendo-color-on-base, #3d3d3d);\n    background-color: rgba(21, 31, 44, 0.88);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
