import React, { useState, useEffect } from "react";
import { Container, Stack, Button, Modal, Form, Table } from "react-bootstrap";
import {
  fetchProfessions,
  fetchAddProfession,
  fetchEditProfession,
  fetchDeleteProfession,
} from "../libs/api";
import { PageHeader } from "../components/PageHeader";

export function ProfessionsPage() {
  const [professions, setProfessions] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentProfession, setCurrentProfession] = useState({
    id: null,
    name: "",
  });
  const [newProfessionName, setNewProfessionName] = useState("");

  useEffect(() => {
    fetchProfessions()
      .then((data) => setProfessions(data.professions))
      .catch((error) => {
        console.error("There was an error fetching professions!", error);
        alert(error.message);
      });
  }, []);

  const handleShowEditModal = (profession) => {
    setCurrentProfession(profession);
    setShowEditModal(true);
  };

  const handleShowDeleteModal = (profession) => {
    setCurrentProfession(profession);
    setShowDeleteModal(true);
  };

  const handleAddProfession = (e) => {
    e.preventDefault();
    fetchAddProfession(newProfessionName)
      .then((data) => {
        setProfessions([...professions, data.profession]);
        setNewProfessionName("");
        setShowAddModal(false);
      })
      .catch((error) => {
        console.error("There was an error adding the profession!", error);
        alert(error.message);
      });
  };

  const handleEditProfession = (e) => {
    e.preventDefault();
    fetchEditProfession(currentProfession.id, currentProfession.name)
      .then((data) => {
        setProfessions(
          professions.map((p) =>
            p.id === currentProfession.id ? data.profession : p
          )
        );
        setShowEditModal(false);
      })
      .catch((error) => {
        console.error("There was an error editing the profession!", error);
        alert(error.message);
      });
  };

  const handleDeleteProfession = () => {
    fetchDeleteProfession(currentProfession.id)
      .then(() => {
        setProfessions(
          professions.filter((p) => p.id !== currentProfession.id)
        );
        setShowDeleteModal(false);
      })
      .catch((error) => {
        console.error("There was an error deleting the profession!", error);
        alert(error.message);
      });
  };

  return (
    <Container>
      <PageHeader title="Professions">
        <Button variant="primary" onClick={() => setShowAddModal(true)}>
          Add Profession
        </Button>
      </PageHeader>
      <div className="card card-table">
        <Table hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {professions.map((profession) => (
              <tr key={profession.id}>
                <td>{profession.id}</td>
                <td>{profession.name}</td>
                <td>
                  <Button
                    variant="warning"
                    size="sm"
                    onClick={() => handleShowEditModal(profession)}
                    className="me-2"
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleShowDeleteModal(profession)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Add Profession Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Profession</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddProfession}>
            <Form.Group controlId="formProfessionName">
              <Form.Label>Profession Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter profession"
                value={newProfessionName}
                onChange={(e) => setNewProfessionName(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleAddProfession}
            disabled={!newProfessionName.trim()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Profession Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profession</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditProfession}>
            <Form.Group controlId="formEditProfessionName">
              <Form.Label>Profession Name</Form.Label>
              <Form.Control
                type="text"
                value={currentProfession.name}
                onChange={(e) =>
                  setCurrentProfession({
                    ...currentProfession,
                    name: e.target.value,
                  })
                }
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleEditProfession}
            disabled={!currentProfession.name.trim()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Profession</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete "{currentProfession.name}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteProfession}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
