import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { PermissionProvider } from "./hooks/PermissionContext.js";
import "./i18n.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/tabler.min.css";
import "./styles/index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

// TODO: move provider to App.js
root.render(
  // <React.StrictMode>
  <PermissionProvider>
    <App />
  </PermissionProvider>
  // </React.StrictMode>
);
