import React, {useState, useEffect} from 'react';


export function useWebsocket(port) {
    // const {port, api_key} = props;
    const [websocket, setWebsocket] = useState(null);
    const [message, setMessage] = useState(null);
    const [ws_connected, setConnected] = useState(false);


    const initWebsocket = (user) => {
        console.log("%cConnecting to websocket", "background: #222; color: #bada55");
        var host = window.location.hostname;
        var schema = window.location.protocol == "https:" ? "wss:" : "ws:";
        // const ws = new WebSocket(`${schema}//${host}:${port}/${user?.api_key}/`);
        const ws = new WebSocket(`${schema}//${host}/adminpro-ws/${user?.api_key}/`);
        ws.onopen = (event) => {
            setConnected(true);
            console.log("%cConnected to websocket", "background: #222; color: #bada55");
        };
        ws.onmessage = function (event) {
            const json = JSON.parse(event.data);
            setMessage(json);
        };
        ws.onclose = function (event) {
            console.log('%cWebSocket is closed now.', 'background: #222; color: #eb4034');
            setConnected(false);
            initWebsocket(user);
        }
        setWebsocket(ws);
        return ws;
    }


    const ws_send = (data) => {
        if (websocket === null) {
            return;
        }
        websocket.send(JSON.stringify(data));
    }

    const ws_status = () => {
        if (websocket === null) {
            return 0;
        }
        return websocket.readyState;
    }


    return {ws_send, ws_status, message, initWebsocket, ws_connected};
}


