import React, { useEffect, useState } from "react";
import { useLanguageStore } from "../../hooks/useLanguage";
import Modal from "react-bootstrap/Modal";
import {
  FaSave,
  FaTimes,
  FaUserPlus,
  FaTrash,
  FaPencilAlt,
  FaPlus,
} from "react-icons/fa";
import { getCookie } from "../../libs/utils";
import toast from "react-hot-toast";

const glowShadowStyle = {
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
};

export const RealModal = (props) => {
  const { t } = useLanguageStore();
  const {
    onHide,
    theme,
    incomeTypeToEdit,
    handleShowAddFundToIncomeTypeModal,
    setIncomeTypeToEdit,
    updateIncomeType,
  } = props;

  const canSaveFunds = () => {
    let totalPercentage = 0;
    incomeTypeToEdit?.independent_funds?.map((fund) => {
      totalPercentage += fund.percentage;
    });
    return totalPercentage === 100;
  };

  const handleSliderChange = (e, fund) => {
    const newValue = Number(e.target.value);
    setIncomeTypeToEdit({
      ...incomeTypeToEdit,
      independent_funds: incomeTypeToEdit.independent_funds.map((f) => {
        if (f.id === fund.id) {
          return {
            ...f,
            percentage: newValue,
          };
        }
        return f;
      }),
    });
  };

  const handleRemoveFund = (fund) => {
    setIncomeTypeToEdit({
      ...incomeTypeToEdit,
      independent_funds: incomeTypeToEdit.independent_funds.filter(
        (f) => f.id !== fund.id
      ),
    });
  };

  const handleIncomeTypeNameChanged = (e) => {
    setIncomeTypeToEdit({
      ...incomeTypeToEdit,
      name: e.target.value,
    });
  };

  const handleSaveClicked = () => {
    updateIncomeType(incomeTypeToEdit);
    onHide();
  };

  return (
    <>
      <Modal.Body className={`box-shadow-${theme} rounded-3`}>
        <div className="row">
          <div className="d-flex col-lg-12">
            <h3>
              {t("Edit Income Type")}
              {/*<span className={"badge bg-primary-lt ms-2"}>{selectedFamily?.members?.length} Member(s)</span>*/}
            </h3>
            <FaTimes
              size={20}
              className={"ms-auto cursor-pointer"}
              onClick={onHide}
            />
          </div>
        </div>
        <div className="pt-0 row">
          <div className="col-xl-6 col-lg-6 col-12">
            <div className="form-group">
              <label htmlFor="name">{t("Name")}</label>
              <input
                type="text"
                value={incomeTypeToEdit?.name}
                onInput={handleIncomeTypeNameChanged}
                className="form-control"
                id="name"
                placeholder={t("Enter name")}
              />
            </div>
          </div>
          <div className="col-12 mt-3">
            <div className={"d-flex justify-items-center align-items-center"}>
              <h4 className={"mb-0 overflow-auto ps-1"}>{t("Funds")}</h4>
              <span
                onClick={handleShowAddFundToIncomeTypeModal}
                className={
                  "ms-2 badge badge-pill bg-primary-lt p-2 d-flex cursor-pointer"
                }
              >
                <FaPlus />
                <span className={"ms-1"}>{t("Add Fund")}</span>
              </span>
            </div>
            <div className="row mt-3">
              {incomeTypeToEdit?.independent_funds?.map((fund, fundIndex) => {
                return (
                  <div className="col-lg-4 mb-3">
                    <div className={`card p-2`} style={glowShadowStyle}>
                      <h5 className="m-0 h4 text-center">{fund?.name}</h5>

                      <div className="my-1">
                        <input
                          type="range"
                          id="fundPercentageSlider"
                          className="form-range"
                          min="0"
                          max="100"
                          step="5"
                          value={fund?.percentage}
                          onChange={(e) => handleSliderChange(e, fund)}
                        />
                      </div>

                      <div className={"mt-1 rounded text-center"}>
                        <span className={"badge bg-success-lt p-2"}>
                          {fund?.percentage}%
                        </span>
                        <span
                          onClick={() => handleRemoveFund(fund)}
                          className={
                            "badge bg-danger-lt p-2 ms-2 cursor-pointer"
                          }
                        >
                          <FaTrash />
                        </span>
                        {/* <span className={"badge bg-warning-lt p-2 ms-2 cursor-pointer"}>
                          <FaPencilAlt />
                        </span> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <div className="d-flex justify-content-start">
                  <button
                    onClick={handleSaveClicked}
                    className={"btn btn-primary"}
                    disabled={!canSaveFunds()}
                  >
                    <FaSave className={"me-2"} />
                    {t("Save")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export const EditIncomeTypeModal = (props) => {
  let { show, onHide, theme } = props;
  return (
    <Modal
      size={"xl"}
      show={show}
      onHide={onHide}
      className={`modal-custom-backdrop-${theme}`}
      backdrop={"static"}
    >
      {show && <RealModal {...props} />}
    </Modal>
  );
};
