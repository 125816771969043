import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useLanguageStore } from "../hooks/useLanguage";
import { MemberList } from "../components/MemberList";
import { AddFamilyModal } from "../components/modals/AddFamilyModal";
import { useTheme } from "../hooks/useTheme";
import { usePermissions } from "../hooks/PermissionContext";
import { EditFamilyModal } from "../components/modals/EditFamilyModal";
import { MemberModal } from "../components/modals/MemberModal";
import { useDebounce } from "../hooks/useDebounce";
import { ButtonGroup, Button } from "react-bootstrap";
import { FaList, FaUserPlus, FaTable, FaBars } from "react-icons/fa6";
import {
  fetchMember,
  fetchMembers,
  fetchFamily,
  fetchMemberVisits,
  fetchEditMember,
} from "../libs/api";
import toast from "react-hot-toast";
import "../styles/Custom.css";
import { desktopMediaQuery } from "../libs/consts";
import { useMediaQuery } from "../hooks/useMediaQuery";

export const Members = (props) => {
  const { t } = useLanguageStore();
  const permissions = usePermissions();
  const { user } = useAuth();
  const { theme } = useTheme();
  const isDesktop = useMediaQuery(desktopMediaQuery);

  const [members, setMembers] = useState([]);
  const [titles, setTitles] = useState([]);
  const [noteTypes, setNoteTypes] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showAddFamilyModal, setShowAddFamilyModal] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [membersLoading, setMembersLoading] = useState(false);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showEditFamilyModal, setShowEditFamilyModal] = useState(false);
  const [memberVisits, setMemberVisits] = useState([]);
  // FILTERS - should be moved to custom hook
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState("UniqueLidNo");
  const [sortOrder, setSortOrder] = useState("asc");
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [layout, setLayout] = useState("table");

  const debouncedSearch = useDebounce(search, 1000);

  const getMembers = () => {
    setMembersLoading(true);
    fetchMembers({
      page,
      order: sortOrder,
      dir: sortBy,
      search: debouncedSearch ?? search,
      limit,
    })
      .then((data) => {
        if (data?.total_pages) {
          setTotalPages(data.total_pages);
          setMembers(data?.members);
          setPage(data?.page);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setMembersLoading(false);
      });
  };

  useEffect(() => {
    setMembersLoading(true);
    fetchMembers({
      page,
      order: sortOrder,
      dir: sortBy,
      search: debouncedSearch,
      limit,
    })
      .then((data) => {
        if (data?.total_pages) {
          setTotalPages(data.total_pages);
          setMembers(data?.members);
          setPage(data?.page);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setMembersLoading(false);
      });
  }, [sortBy, sortOrder, page, limit, debouncedSearch]);

  useEffect(() => {
    setLayout(isDesktop ? "table" : "list");
  }, [isDesktop]);

  const selectedFamilyChanged = (family_id) => {
    fetchFamily(family_id)
      .then((data) => {
        if (data?.success) {
          setSelectedFamily(data?.data);
        } else {
          toast.error(t("Failed to get family data"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectedMemberChanged = (member) => {
    fetchMember(member?.id)
      .then((data) => {
        if (data?.success) {
          setSelectedMember(data?.data);
          setTitles(data?.titles);
          setProfessions(data?.professions);
          setNoteTypes(data?.noteTypes);
        } else {
          toast.error(t("Failed to get member data"));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    fetchMemberVisits(member).then((data) => {
      if (data?.status === 0) {
        setMemberVisits(data?.visits);
      } else {
        toast.error(t("Failed to get member visits"));
      }
    });
  };

  const rowClick = (e, member) => {
    setSelectedFamily(null);
    selectedFamilyChanged(member?.family_id);
    setShowEditFamilyModal(true);
  };

  const saveEditedMember = (member) => {
    const formData = new FormData();
    for (const key in member) {
      formData.append(key, member[key]);
    }
    return fetchEditMember(formData);
  };

  const searchChanged = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  return (
    <>
      {permissions?.permissions?.includes("modify_members") && (
        <AddFamilyModal
          show={showAddFamilyModal}
          onShow={() => setShowAddFamilyModal(true)}
          onHide={() => setShowAddFamilyModal(false)}
          user={user}
        />
      )}

      {permissions?.permissions?.includes("view_members") && (
        <EditFamilyModal
          show={showEditFamilyModal}
          onShow={() => setShowAddFamilyModal(false)}
          onHide={() => setShowEditFamilyModal(false)}
          selectedFamily={selectedFamily}
          selectedMember={selectedMember}
          theme={theme}
          selectedMemberChanged={selectedMemberChanged}
          handleShowMemberModal={() => setShowMemberModal(true)}
          setSelectedFamily={setSelectedFamily}
          getMembers={getMembers}
          getFamily={selectedFamilyChanged}
        />
      )}

      {permissions?.permissions?.includes("view_members") && (
        <MemberModal
          show={showMemberModal}
          onShow={() => setShowMemberModal(true)}
          onHide={() => setShowMemberModal(false)}
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
          selectedMemberChanged={selectedMemberChanged}
          theme={theme}
          memberVisits={memberVisits}
          saveEditedMember={saveEditedMember}
          selectedFamilyChanged={selectedFamilyChanged}
          titles={titles}
          setTitles={setTitles}
          professions={professions}
          setProfessions={setProfessions}
          noteTypes={noteTypes}
          setNoteTypes={setNoteTypes}
          user={user}
        />
      )}

      {/* Main Content */}
      {permissions?.permissions?.includes("view_members") && (
        <div className="container h-100">
          <div className="d-flex justify-content-between">
            <h1 className={`page-header-${theme}`}>{t("Members")}</h1>
            <div>
              {/* Layout Switcher - to be removed */}
              <ButtonGroup size="md" className="d-none d-lg-inline-flex">
                <Button
                  variant={layout === "table" ? "primary" : "ghost"}
                  onClick={() => setLayout("table")}
                >
                  <FaList className="me-1" />
                  {t("Table")}
                </Button>
                <Button
                  variant={layout === "grid" ? "primary" : "ghost"}
                  onClick={() => setLayout("grid")}
                >
                  <FaTable className="me-1" />
                  {t("Grid")}
                </Button>
                <Button
                  variant={layout === "list" ? "primary" : "ghost"}
                  onClick={() => setLayout("list")}
                >
                  <FaBars className="me-1" />
                  {t("List")}
                </Button>
              </ButtonGroup>
              {/* Add Family Button */}
              {permissions?.permissions?.includes("modify_members") && (
                <Button
                  onClick={() => setShowAddFamilyModal(true)}
                  className="ms-2"
                >
                  <FaUserPlus />
                  <span className="d-none d-lg-block ms-2">
                    {t("Add Family")}
                  </span>
                </Button>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <MemberList
                members={members}
                showSearchModal={showSearchModal}
                handleShowSearchModal={() => setShowSearchModal(true)}
                handleCloseSearchModal={() => setShowSearchModal(false)}
                rowClick={rowClick}
                handleShowAddFamilyModal={() => setShowAddFamilyModal(true)}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                setSearch={setSearch}
                searchChanged={searchChanged}
                search={search}
                selectedMemberChanged={selectedMemberChanged}
                theme={theme}
                membersLoading={membersLoading}
                limit={limit}
                setLimit={setLimit}
                layout={layout}
                setLayout={setLayout}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
