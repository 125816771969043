import React, { useEffect, useState, useRef } from "react";
import { CreditPurchaseForm } from "../components/CreditPurchaseForm";

import toast from "react-hot-toast";
import { useLanguageStore } from "../hooks/useLanguage";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../hooks/useTheme";
import { usePermissions } from "../hooks/PermissionContext";
import { useWebsocket } from "../hooks/useWebsocket";
import { useEnv } from "../hooks/useEnv";
import { fetchMessageHistory, fetchMessageMemberSearch } from "../libs/api";
import {
  FaEnvelope,
  FaTelegram,
  FaRegMessage,
  FaWhatsapp,
  FaUserPlus,
  FaUserMinus,
} from "react-icons/fa6";
import { MessageManuallyAddRecipientModal } from "./modals/MessageManuallyAddRecipientModal";

function SendMessage(props) {
  const { t } = useLanguageStore();
  const { theme } = useTheme();
  const [recipients, setRecipients] = useState([]);
  const [messageType, setMessageType] = useState("sms");
  const [sendMessageType, setSendMessageType] = useState("sms");
  const [messageContent, setMessageContent] = useState("");
  const [messageSubject, setMessageSubject] = useState("");
  const [wsMessage, setWsMessage] = useState(null);
  const { env } = useEnv();
  // const { ws_send, ws_status, message, initWebsocket, ws_connected } = useWebsocket(env?.REACT_APP_ADMINPRO_WS_PORT);
  const [daysFilter, setDaysFilter] = useState("7");
  const [typeFilter, setTypeFilter] = useState("all");
  const [memberSearchPhrase, setMemberSearchPhrase] = useState("");
  const [memberResults, setMemberResults] = useState([]);
  const [memberSearchLoading, setMemberSearchLoading] = useState(false);
  const [showManuallyAddRecipientModal, setShowManuallyAddRecipientModal] =
    useState(false);
  const [manualRecipients, setManualRecipients] = useState([]);
  const debounceTimeout = useRef(null);

  const { user, creditData } = props;

  useEffect(() => {}, []);

  const getMembers = () => {
    setMemberSearchLoading(true);
    fetchMessageMemberSearch(memberSearchPhrase, sendMessageType)
      .then((data) => {
        setMemberResults(data);
        setMemberSearchLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    getMembers();
  }, [memberSearchPhrase, sendMessageType]);

  // useEffect(() => {
  //   if (user) {
  //     initWebsocket(user);
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (message) {
  //     if (message?.type === "messaging") {
  //       setWsMessage(message?.data);
  //     }
  //   }
  // }, [message]);

  const addMemberClicked = (member) => {
    setRecipients([...recipients, member]);
  };

  const memberSearchChanged = (e) => {
    setMemberSearchPhrase(e.target.value);
  };

  const sendMessageTypeChange = (e) => {
    if (recipients?.length > 0) {
      if (
        !window.confirm(
          t(
            "Changing the message type will clear the recipients list. Are you sure you want to continue?"
          )
        )
      ) {
        return;
      }
    }
    setSendMessageType(e.target.value);
    setRecipients([]);
  };

  const CreditsDisplay = () => {
    return (
      <div className="d-flex justify-content-center align-items-center flex-row">
        <span>
          {sendMessageType === "telegram" && (
            <FaTelegram color={"#4299e1"} size={18} />
          )}
          {sendMessageType === "sms" && (
            <FaRegMessage color={"#f59f00"} size={18} />
          )}
          {sendMessageType === "email" && (
            <FaEnvelope color={"#0ca678"} size={18} />
          )}
          {sendMessageType === "whatsapp" && (
            <FaWhatsapp color={"#0ca678"} size={18} />
          )}
        </span>
        <h2 className={"mb-0 ms-2"}>
          {sendMessageType === "sms" && creditData?.sms_credits}
          {sendMessageType === "whatsapp" && creditData?.whatsapp_credits}
          {sendMessageType === "telegram" && creditData?.telegram_credits}
          {sendMessageType === "email" && creditData?.email_credits}
          <span className="ms-1">{t("Credits")}</span>
        </h2>
      </div>
    );
  };

  const RecipientItem = ({ recipient }) => {
    return (
      <tr>
        <td>{recipient?.Names}</td>
        <td>
          {sendMessageType === "sms" && recipient?.phone_cell}
          {sendMessageType === "whatsapp" && recipient?.phone_cell}
          {sendMessageType === "email" && recipient?.member_email}
          {/* {sendMessageType === "telegram" && recipient?.member_email} */}
        </td>
        <td>
          <button
            onClick={() => {
              setRecipients(recipients.filter((r) => r?.id !== recipient?.id));
            }}
            className="btn btn-sm btn-danger"
          >
            <FaUserMinus className="me-1" />
            {t("Remove")}
          </button>
        </td>
      </tr>
    );
  };

  const isMemberInRecipients = (member) => {
    return recipients?.findIndex((r) => r?.id === member?.id) > -1;
  };

  return (
    <>
      <MessageManuallyAddRecipientModal
        show={showManuallyAddRecipientModal}
        onHide={() => setShowManuallyAddRecipientModal(false)}
        theme={theme}
        messageType={sendMessageType}
        manualRecipients={manualRecipients}
        setManualRecipients={setManualRecipients}
      />
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="card">
            <div className="p-2 px-3">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="m-0">{t("Send message")}</h2>
                <div>
                  <CreditsDisplay />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="messageType">{t("Message type")}</label>
                    <select
                      value={sendMessageType}
                      onChange={sendMessageTypeChange}
                      name=""
                      id="messageType"
                      className="form-control"
                    >
                      <option value="sms">SMS</option>
                      <option value="email">Email</option>
                      <option value="telegram">Telegram</option>
                      <option value="whatsapp">WhatsApp</option>
                    </select>
                  </div>
                </div>
                {sendMessageType === "email" ? (
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="messageType">{t("Subject")}</label>
                      <input
                        type="text"
                        value={messageSubject}
                        onChange={(e) => setMessageSubject(e.target.value)}
                        name=""
                        id="messageSubject"
                        className="form-control"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row mt-3">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="messageContent">{t("Message")}</label>
                    <textarea
                      maxLength={sendMessageType === "sms" ? 160 : null}
                      style={{ resize: "none" }}
                      value={messageContent}
                      onChange={(e) => setMessageContent(e.target.value)}
                      name=""
                      id="messageContent"
                      className="form-control"
                      rows="5"
                    ></textarea>
                    {sendMessageType === "sms" ? (
                      <span className="text-muted px-2">
                        {160 - messageContent?.length} / 160
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="py-2">
                    <button
                      onClick={() => setShowManuallyAddRecipientModal(true)}
                      className="btn btn-sm btn-primary"
                    >
                      <FaUserPlus className="me-1" />
                      {t("Manually add recipient")}
                    </button>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>{t("Recipient")}</th>
                        <th>
                          {sendMessageType === "sms" && t("Number")}
                          {sendMessageType === "email" && t("Email Address")}
                          {sendMessageType === "whatsapp" && t("Number")}
                          {/* {sendMessageType === "telegram" && t('ChatID')} */}
                        </th>
                        <th>{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recipients?.map((recipient, index) => (
                        <RecipientItem key={index} recipient={recipient} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="card">
            <div className="p-2 px-3">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="m-0">{t("Members")}</h2>
                <div className="d-flex justify-content-center align-items-center flex-row">
                  <input
                    onInput={memberSearchChanged}
                    value={memberSearchPhrase}
                    type="text"
                    className="form-control"
                    placeholder={t("Search...")}
                  />
                </div>
              </div>
            </div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>{t("Lid #")}</th>
                  <th>{t("Name")}</th>
                  <th>{t("Number")}</th>
                  <th>{t("Email")}</th>
                  <th>{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {memberResults?.map((member, index) => {
                  return (
                    <tr key={index}>
                      <td>{member?.UniqueLidNo}</td>
                      <td>{member?.Names}</td>
                      <td>{member?.phone_cell}</td>
                      <td>{member?.member_email}</td>
                      <td>
                        {isMemberInRecipients(member) ? (
                          <>
                            <span>{t("Added")}</span>
                          </>
                        ) : (
                          <button
                            onClick={() => {
                              setRecipients([...recipients, member]);
                            }}
                            className="btn btn-sm btn-primary"
                          >
                            <FaUserPlus className="me-1" />
                            {t("Add")}
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default SendMessage;
