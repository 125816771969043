import React from "react";
import LogoUpload from "../components/LogoUpload";
import { useLanguageStore } from "../hooks/useLanguage";
import { Input } from "@progress/kendo-react-inputs";
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
} from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { AutoComplete, ComboBox } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Upload } from "@progress/kendo-react-upload";
import { loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { FaUser } from "react-icons/fa";
import "../styles/CompanySetup.css";
import { getCookie } from "../libs/utils";
import toast from "react-hot-toast";

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const emailValidator = (value) =>
  emailRegex.test(value) ? "" : "Please enter a valid email.";
const EmailInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div className="k-form-field-wrap">
      <Input {...others} labelClassName={"k-form-label"} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const CompanySetup = () => {
  const { t } = useLanguageStore();

  const [files, setFiles] = React.useState([]);
  const [companyDetails, setCompanyDetails] = React.useState({});
  const [isChanged, setIsChanged] = React.useState(false);

  React.useEffect(() => {
    getCompanyDetails();
  }, []);

  const getCompanyDetails = () => {
    getCompanyDetails_api()
      .then((response) => {
        setCompanyDetails(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getCompanyDetails_api = () => {
    return new Promise((resolve, reject) => {
      fetch("/apiv2/company_details/")
        .then((response) => response.json())
        .then((data) => {
          data.success ? resolve(data) : reject(data);
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(error);
        });
    });
  };

  const handleSubmit = (dataItem) => {
    const formData = new FormData();
    for (const key in dataItem) {
      formData.append(key, dataItem[key]);
    }
    if (files[0]) {
      formData.append("logo", files[0].getRawFile());
    }

    updateCompanyDetails(formData);
  };

  const updateCompanyDetails = (formData) => {
    CompanyDetails_api(formData)
      .then((response) => {
        toast.success(t("Company Details Updated Successfully"));
        window.location.reload(true);
      })
      .catch((error) => {
        toast.error(t("Company Details Update Failed"));
        console.log("error", error);
      });
  };

  const CompanyDetails_api = (formData) => {
    return new Promise((resolve, reject) => {
      fetch("/apiv2/company_details/", {
        method: "POST",
        headers: {
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          data.success ? resolve(data) : reject(data);
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(error);
        });
    });
  };

  loadMessages(
    {
      upload: {
        dropFilesHere: "Drop logo here to upload",
        select: "Upload Logo",
      },
    },
    "myCustomMessages"
  );

  const onAdd = (event) => {
    const file = event.affectedFiles[0].getRawFile();
    setFiles(event.newState);
    setCompanyDetails((prevDetails) => ({ ...prevDetails, logo: file }));
    setIsChanged(true);
  };

  const onError = (event) => {
    console.error("Upload failed", event);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card-container">
            <div className="card">
              <div className="row">
                <div className="d-flex col-lg-12">
                  <h1>{t("Company Setup")}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="card">
            <h4 className="text-center p-1 bg-primary-lt mb-0">
              {t("Information")}
            </h4>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <Form
                    onSubmit={handleSubmit}
                    render={(formRenderProps) => (
                      <FormElement style={{ maxWidth: 650 }}>
                        <fieldset className={"k-form-fieldset"}>
                          <legend className={"k-form-legend"}>
                            {" "}
                            Please fill in the fields:
                          </legend>
                          <div className="k-form-field-wrap">
                            <div className="form-group d-flex">
                              {companyDetails?.company?.logo ? (
                                <img
                                  style={{
                                    borderRadius: "50%",
                                    width: "100%",
                                    maxWidth: "100px",
                                    height: "auto",
                                    objectFit: "cover",
                                    objectPosition: "center",
                                    marginBottom: "10px",
                                    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                  }}
                                  src={companyDetails.company.logo}
                                  alt="Company Logo"
                                />
                              ) : (
                                <FaUser
                                  style={{
                                    borderRadius: "50%",
                                    width: "100%",
                                    maxWidth: "100px",
                                    height: "auto",
                                    marginBottom: "10px",
                                    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                    color: "rgba(124,116,200,0.1)",
                                  }}
                                />
                              )}

                              <div className="k-form-field-wrap">
                                <Field
                                  name={"companyName"}
                                  component={Input}
                                  labelClassName={"k-form-label"}
                                  label={
                                    companyDetails?.company?.name
                                      ? companyDetails.company.name
                                      : t("Business Name")
                                  }
                                />
                              </div>
                            </div>

                            <div className="k-form-field-wrap">
                              <Field
                                name={"logo"}
                                component={LogoUpload}
                                labelClassName={"k-form-label"}
                                label={
                                  companyDetails?.company?.logo
                                    ? companyDetails.company.logo
                                    : t("Logo")
                                }
                                setFiles={setFiles}
                                setCompanyDetails={setCompanyDetails}
                                setIsChanged={setIsChanged}
                                files={files}
                                onError={onError}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div>
                              <FieldWrapper>
                                <div className="k-form-field-wrap">
                                  <h3>Contact Details</h3>
                                </div>
                              </FieldWrapper>

                              <FieldWrapper>
                                <div className="k-form-field-wrap">
                                  <Field
                                    name={"phonenumber"}
                                    component={Input}
                                    labelClassName={"k-form-label"}
                                    label={
                                      companyDetails?.company?.business_phone
                                        ? companyDetails.company.business_phone
                                        : t("Business Phone Number")
                                    }
                                  />
                                </div>
                              </FieldWrapper>
                              <FieldWrapper>
                                <Field
                                  name={"email"}
                                  type={"email"}
                                  component={EmailInput}
                                  label={
                                    companyDetails?.company?.business_email
                                      ? companyDetails.company.business_email
                                      : t("Email")
                                  }
                                  validator={
                                    !companyDetails?.company?.business_email
                                      ? emailValidator
                                      : null
                                  }
                                />
                              </FieldWrapper>
                            </div>
                            <div className="form-group">
                              <div className="k-form-group">
                                <FieldWrapper>
                                  <div className="k-form-field-wrap">
                                    <h3>Business Address</h3>
                                  </div>
                                </FieldWrapper>

                                <FieldWrapper>
                                  <div className="k-form-field-wrap">
                                    <Field
                                      name={"businessAdress"}
                                      component={Input}
                                      labelClassName={"k-form-label"}
                                      label={
                                        companyDetails?.company
                                          ?.business_address
                                          ? companyDetails.company
                                              .business_address
                                          : t("Business Address")
                                      }
                                    />
                                  </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                  <div className="k-form-field-wrap">
                                    <Field
                                      name={"businessCity"}
                                      component={Input}
                                      label={
                                        companyDetails?.company?.business_city
                                          ? companyDetails.company.business_city
                                          : t("City")
                                      }
                                    />
                                  </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                  <div className="k-form-field-wrap">
                                    <Field
                                      name={"postalCode"}
                                      component={Input}
                                      label={
                                        companyDetails?.company
                                          ?.business_postal_code
                                          ? companyDetails.company
                                              .business_postal_code
                                          : t("Postal Code")
                                      }
                                    />
                                  </div>
                                </FieldWrapper>
                              </div>
                              <div className="form-group">
                                <div className="k-form-group">
                                  <FieldWrapper>
                                    <div className="k-form-field-wrap">
                                      <h3>Postal Address</h3>
                                    </div>
                                  </FieldWrapper>

                                  <FieldWrapper>
                                    <div className="k-form-field-wrap">
                                      <Field
                                        name={"businessPostalAdress"}
                                        component={Input}
                                        labelClassName={"k-form-label"}
                                        label={
                                          companyDetails?.company
                                            ?.postal_address
                                            ? companyDetails.company
                                                .postal_address
                                            : t("Business Postal Address")
                                        }
                                      />
                                    </div>
                                  </FieldWrapper>
                                  <FieldWrapper>
                                    <div className="k-form-field-wrap">
                                      <Field
                                        name={"businessPostalCity"}
                                        component={Input}
                                        label={
                                          companyDetails?.company?.postal_city
                                            ? companyDetails.company.postal_city
                                            : t("Postal City")
                                        }
                                      />
                                    </div>
                                  </FieldWrapper>
                                  <FieldWrapper>
                                    <div className="k-form-field-wrap">
                                      <Field
                                        name={"postalPostalCode"}
                                        component={Input}
                                        label={
                                          companyDetails?.company
                                            ?.postal_postal_code
                                            ? companyDetails.company
                                                .postal_postal_code
                                            : t("Postal Postal Code")
                                        }
                                      />
                                    </div>
                                  </FieldWrapper>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div className="k-form-buttons">
                          <Button
                            disabled={!formRenderProps.allowSubmit}
                            // disabled={!isChanged}
                            primary={true}
                            type={"submit"}
                          >
                            {t("Submit")}
                          </Button>
                        </div>
                      </FormElement>
                    )}
                  />

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card">
            <h4 className="text-center p-1 bg-primary-lt mb-0">
              {t("Invoice Information")}
            </h4>
            <div className="row p-3">
              <div className="col-lg-12">
                <div className="form-group pb-2 d-flex align-items-center">
                  <Form
                    onSubmit={handleSubmit}
                    render={(formRenderProps) => (
                      <FormElement
                        style={{
                          maxWidth: 650,
                        }}
                      >
                        <fieldset className={"k-form-fieldset"}>
                          <legend className={"k-form-legend"}>
                            Please fill in the fields:
                          </legend>
                          <FieldWrapper>
                            <div className="k-form-field-wrap">
                              <Field
                                name={"companyName"}
                                component={Input}
                                labelClassName={"k-form-label"}
                                label={
                                  companyDetails?.company?.name
                                    ? companyDetails.company.name
                                    : t("Business Name")
                                }
                                // label={t("Business Name")}
                              />
                            </div>
                          </FieldWrapper>

                          <FieldWrapper>
                            <div className="k-form-field-wrap">
                              <Field
                                name={"phonenumber"}
                                component={Input}
                                labelClassName={"k-form-label"}
                                label={
                                  companyDetails?.company?.business_phone
                                    ? companyDetails.company.business_phone
                                    : t("Business Phone Number")
                                }
                              />
                            </div>
                          </FieldWrapper>

                          <FieldWrapper>
                            <Field
                              name={"email"}
                              type={"email"}
                              component={EmailInput}
                              label={
                                companyDetails?.company?.business_email
                                  ? companyDetails.company.business_email
                                  : t("Email")
                              }
                              validator={emailValidator}
                            />
                          </FieldWrapper>
                          <div className="form-group">
                            <div className="k-form-group">
                              <FieldWrapper>
                                <div className="k-form-field-wrap">
                                  <h3>Business Address</h3>
                                </div>
                              </FieldWrapper>

                              <FieldWrapper>
                                <div className="k-form-field-wrap">
                                  <Field
                                    name={"businessAdress"}
                                    component={Input}
                                    labelClassName={"k-form-label"}
                                    label={
                                      companyDetails?.company?.business_address
                                        ? companyDetails.company
                                            .business_address
                                        : t("Business Address")
                                    }
                                  />
                                </div>
                              </FieldWrapper>
                              <FieldWrapper>
                                <div className="k-form-field-wrap">
                                  <Field
                                    name={"businessCity"}
                                    component={Input}
                                    label={
                                      companyDetails?.company?.business_city
                                        ? companyDetails.company.business_city
                                        : t("City")
                                    }
                                  />
                                </div>
                              </FieldWrapper>
                              <FieldWrapper>
                                <div className="k-form-field-wrap">
                                  <Field
                                    name={"postalCode"}
                                    component={Input}
                                    label={
                                      companyDetails?.company
                                        ?.business_postal_code
                                        ? companyDetails.company
                                            .business_postal_code
                                        : t("Postal Code")
                                    }
                                  />
                                </div>
                              </FieldWrapper>
                            </div>
                            <div className="form-group">
                              <div className="k-form-group">
                                <FieldWrapper>
                                  <div className="k-form-field-wrap">
                                    <h3>Postal Address</h3>
                                  </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                  <div className="k-form-field-wrap">
                                    <Field
                                      name={"businessPostalAdress"}
                                      component={Input}
                                      labelClassName={"k-form-label"}
                                      label={
                                        companyDetails?.company?.postal_address
                                          ? companyDetails.company
                                              .postal_address
                                          : t("Business Postal Address")
                                      }
                                    />
                                  </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                  <div className="k-form-field-wrap">
                                    <Field
                                      name={"businessPostalCity"}
                                      component={Input}
                                      label={
                                        companyDetails?.company?.postal_city
                                          ? companyDetails.company.postal_city
                                          : t("Postal City")
                                      }
                                    />
                                  </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                  <div className="k-form-field-wrap">
                                    <Field
                                      name={"postalPostalCode"}
                                      component={Input}
                                      label={
                                        companyDetails?.company
                                          ?.postal_postal_code
                                          ? companyDetails.company
                                              .postal_postal_code
                                          : t("Postal Postal Code")
                                      }
                                    />
                                  </div>
                                </FieldWrapper>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div className="k-form-buttons">
                          <Button disabled={!formRenderProps.allowSubmit}>
                            Submit
                          </Button>
                        </div>
                      </FormElement>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
