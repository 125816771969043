import React, { useState, useEffect } from "react";
import {
  Container,
  Accordion,
  Button,
  Modal,
  Form,
  Card,
  Row,
  Col,
  ListGroup,
  InputGroup,
} from "react-bootstrap";
import {
  fetchGroups,
  fetchAddGroup,
  fetchEditGroup,
  fetchDeleteGroup,
  fetchMemberSearch,
} from "../libs/api";
import { PageHeader } from "../components/PageHeader";
import { mobileMediaQuery } from "../libs/consts";
import { useMediaQuery } from "../hooks/useMediaQuery";

const defaultGroup = {
  id: null,
  name: "",
  parent_group: null,
  users: [],
  company: "",
  type: "",
};

export function Groups(props) {
  const isMobile = useMediaQuery(mobileMediaQuery);

  const [groups, setGroups] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(defaultGroup);
  const [newGroupName, setNewGroupName] = useState("");
  const [newGroupParent, setNewGroupParent] = useState(null);
  const [newGroupUsers, setNewGroupUsers] = useState([]);
  const [userSearch, setUserSearch] = useState("");
  const [userSearchResults, setUserSearchResults] = useState([]);
  const [editUserSearch, setEditUserSearch] = useState("");
  const [editSearchResults, setEditUserSearchResults] = useState([]);
  // New state variables for group search
  const [groupSearch, setGroupSearch] = useState("");
  const [expandedGroups, setExpandedGroups] = useState([]);

  useEffect(() => {
    fetchGroups()
      .then((data) => setGroups(data.groups))
      .catch((error) => {
        console.error("There was an error fetching groups!", error);
        alert(error.message);
      });
  }, []);

  const handleShowAddModal = () => {
    setNewGroupParent(null);
    setNewGroupName("");
    setNewGroupUsers([]);
    setShowAddModal(true);
  };

  const handleHideAddModal = () => {
    setNewGroupName("");
    setNewGroupParent(null);
    setNewGroupUsers([]);
    setShowAddModal(false);
    setUserSearch("");
    setUserSearchResults([]);
  };

  const handleShowEditModal = (group) => {
    setCurrentGroup(group);
    setShowEditModal(true);
  };

  const handleHideEditModal = () => {
    setShowEditModal(false);
    setCurrentGroup(defaultGroup);
    setEditUserSearch("");
    setEditUserSearchResults([]);
  };

  const handleShowDeleteModal = (group) => {
    setCurrentGroup(group);
    setShowDeleteModal(true);
  };

  const handleAddGroup = (e) => {
    e.preventDefault();
    fetchAddGroup(
      newGroupName,
      newGroupParent,
      newGroupUsers.map((u) => u.id)
    )
      .then((data) => {
        setGroups([...groups, data.group]);
        handleHideAddModal();
      })
      .catch((error) => {
        console.error("Error adding group!", error);
        alert(error.message);
      });
  };

  const handleEditGroup = (e) => {
    e.preventDefault();
    fetchEditGroup(
      currentGroup.id,
      currentGroup.name,
      currentGroup.parent_group,
      currentGroup.users.map((user) =>
        typeof user === "object" ? user.id : user
      )
    )
      .then((data) => {
        setGroups(
          groups.map((g) => (g.id === currentGroup.id ? data.group : g))
        );
        handleHideEditModal();
      })
      .catch((error) => {
        console.error("Error editing group!", error);
        alert(error.message);
      });
  };

  const handleDeleteGroup = () => {
    fetchDeleteGroup(currentGroup.id)
      .then(() => {
        setGroups(groups.filter((g) => g.id !== currentGroup.id));
        setShowDeleteModal(false);
      })
      .catch((error) => {
        console.error("Error deleting group!", error);
        alert(error.message);
      });
  };

  const handleUserSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    fetchMemberSearch(showEditModal ? editUserSearch : userSearch)
      .then((data) =>
        showEditModal
          ? setEditUserSearchResults(data)
          : setUserSearchResults(data)
      )
      .catch((error) => {
        console.error("Error searching users!", error);
        alert(error.message);
      });
  };

  // Search groups recursively
  const searchGroups = (searchTerm) => {
    if (!searchTerm.trim()) {
      setExpandedGroups([]);
      return;
    }

    const term = searchTerm.toLowerCase();
    const foundGroupIds = new Set();
    const expandedIds = new Set();

    // Find matching groups and their parent paths
    const findMatchingGroups = (groupId = null, parentPath = []) => {
      const children = groups.filter((group) => group.parent_group === groupId);

      children.forEach((group) => {
        // Check if this group matches the search term
        if (group.name.toLowerCase().includes(term)) {
          foundGroupIds.add(group.id);
          // Add all parents to expanded groups
          parentPath.forEach((id) => expandedIds.add(id));
        }

        // Recursively search children
        findMatchingGroups(group.id, [...parentPath, group.id]);
      });
    };

    findMatchingGroups();

    // Also ensure parents of matching groups are expanded
    const ensureParentExpanded = (groupId) => {
      const group = groups.find((g) => g.id === groupId);
      if (group && group.parent_group !== null) {
        expandedIds.add(group.parent_group);
        ensureParentExpanded(group.parent_group);
      }
    };

    foundGroupIds.forEach((id) => ensureParentExpanded(id));

    // Convert to array for accordion
    setExpandedGroups(Array.from(expandedIds).map(String));
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setGroupSearch(value);
    searchGroups(value);
  };

  // Clear search
  const clearSearch = () => {
    setGroupSearch("");
    setExpandedGroups([]);
  };

  // Highlight matching text in group name
  const highlightText = (text, searchTerm) => {
    if (!searchTerm.trim()) return text;

    const regex = new RegExp(`(${searchTerm})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, i) =>
      regex.test(part) ? (
        <span key={i} className="bg-warning">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // Build tree recursively. Groups with parent_group === parentId will be nested.
  const renderGroupTree = (parentId = null) => {
    const children = groups.filter((group) => group.parent_group === parentId);
    if (!children.length) return null;

    return children.map((group) => {
      const isMatch =
        groupSearch &&
        group.name.toLowerCase().includes(groupSearch.toLowerCase());

      return (
        <Accordion.Item
          eventKey={String(group.id)}
          className={`pb-1 ${isMatch ? "bg-warning-lt" : ""}`}
          key={group.id}
        >
          <Accordion.Header>
            {groupSearch ? highlightText(group.name, groupSearch) : group.name}
            {group.users.length > 0 && (
              <span className="ms-2 badge bg-info-lt">
                {group.users.length} Members
              </span>
            )}
          </Accordion.Header>
          <Accordion.Body className="p-0 px-2">
            <div className="mb-2">
              <Button
                variant="secondary"
                size="sm"
                onClick={() => {
                  handleShowAddModal();
                  setNewGroupParent(group.id);
                }}
                className="me-2"
              >
                Add Subgroup
              </Button>
              <Button
                variant="warning"
                size="sm"
                onClick={() => handleShowEditModal(group)}
                className="me-2"
              >
                Edit
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleShowDeleteModal(group)}
              >
                Delete
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  setCurrentGroup(group);
                  setShowMembersModal(true);
                }}
                className="ms-2"
              >
                View Members
              </Button>
            </div>
            {/* Recursively render children */}
            <Accordion>{renderGroupTree(group.id)}</Accordion>
          </Accordion.Body>
        </Accordion.Item>
      );
    });
  };

  return (
    <Container>
      <PageHeader title="Groups">
        <div className="d-flex gap-2">
          <InputGroup>
            <Form.Control
              placeholder="Search groups..."
              value={groupSearch}
              onChange={handleSearchChange}
            />
            {groupSearch && (
              <Button variant="outline-secondary" onClick={clearSearch}>
                ✕
              </Button>
            )}
          </InputGroup>
          <Button variant="primary" onClick={handleShowAddModal}>
            Add Group
          </Button>
        </div>
      </PageHeader>

      <Card className="mb-1 border-0">
        <Accordion
          defaultActiveKey="0"
          activeKey={expandedGroups.length ? expandedGroups : undefined}
        >
          {renderGroupTree()}
        </Accordion>
      </Card>

      {/* View Members Modal */}
      <Modal
        show={showMembersModal}
        onHide={() => setShowMembersModal(false)}
        fullscreen={isMobile}
      >
        <Modal.Header closeButton>
          <Modal.Title>Members of {currentGroup.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="p-0"
          style={{ maxHeight: isMobile ? "100%" : "50vh", overflow: "auto" }}
        >
          <ListGroup>
            {currentGroup.users.map((user) => (
              <ListGroup.Item key={user.id}>
                {user.FullNames} {user.Surname}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowMembersModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Group Modal */}
      <Modal
        show={showAddModal}
        onHide={handleHideAddModal}
        fullscreen={isMobile}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddGroup}>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formGroupName">
                  <Form.Label>Group Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter group name"
                    value={newGroupName}
                    onChange={(e) => setNewGroupName(e.target.value)}
                    autoFocus
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formParentGroup">
                  <Form.Label>Parent Group</Form.Label>
                  <Form.Select
                    value={newGroupParent || ""}
                    onChange={(e) =>
                      setNewGroupParent(
                        e.target.value ? Number(e.target.value) : null
                      )
                    }
                  >
                    <option value="">None (Root Group)</option>
                    {groups.map((grp) => (
                      <option key={grp.id} value={grp.id}>
                        {grp.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Form onSubmit={handleUserSearch}>
            <Form.Group controlId="formUserSearch" className="mt-2">
              <Form.Label>Search Members</Form.Label>
              <div className="d-flex flex-row gap-2 align-items-center">
                <Form.Control
                  type="text"
                  placeholder="Search for members"
                  value={userSearch}
                  onChange={(e) => setUserSearch(e.target.value)}
                />
                <Button
                  type="button"
                  variant="primary"
                  onClick={handleUserSearch}
                >
                  Search
                </Button>
                <Button
                  type="button"
                  variant="ghost"
                  onClick={() => {
                    setUserSearch("");
                    setUserSearchResults([]);
                  }}
                >
                  Clear
                </Button>
              </div>
            </Form.Group>
          </Form>
          <Card
            className="mt-2"
            style={{ maxHeight: isMobile ? "73%" : "50vh", overflow: "auto" }}
          >
            <ListGroup>
              {newGroupUsers.map((user) => (
                <ListGroup.Item
                  key={user.id}
                  className="d-flex flex-row align-items-center"
                  active
                >
                  <Form.Check
                    className="m-0"
                    type="checkbox"
                    id={`selected-member-${user.id}`}
                    label={`${user.FullNames} ${user.Surname}`}
                    checked={true}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setNewGroupUsers(
                          newGroupUsers.filter((u) => u.id !== user.id)
                        );
                      }
                    }}
                  />
                </ListGroup.Item>
              ))}
              {userSearchResults
                .filter((user) => !newGroupUsers.some((u) => u.id === user.id))
                .map((user) => (
                  <ListGroup.Item
                    key={user.id}
                    className="d-flex flex-row align-items-center"
                  >
                    <Form.Check
                      className="m-0"
                      type="checkbox"
                      id={`add-member-${user.id}`}
                      label={
                        `${user.FullNames} ${user.Surname}` || "Unknown Member"
                      }
                      checked={newGroupUsers.some((u) => u.id === user.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setNewGroupUsers([...newGroupUsers, user]);
                        }
                      }}
                    />
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="ghost" onClick={handleHideAddModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleAddGroup}
            disabled={!newGroupName.trim()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Group Modal */}
      <Modal
        show={showEditModal}
        onHide={handleHideEditModal}
        fullscreen={isMobile}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditGroup}>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formEditGroupName">
                  <Form.Label>Group Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={currentGroup.name}
                    onChange={(e) =>
                      setCurrentGroup({
                        ...currentGroup,
                        name: e.target.value,
                      })
                    }
                    autoFocus
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formEditParentGroup">
                  <Form.Label>Parent Group</Form.Label>
                  <Form.Select
                    value={currentGroup.parent_group || ""}
                    onChange={(e) =>
                      setCurrentGroup({
                        ...currentGroup,
                        parent_group: e.target.value
                          ? Number(e.target.value)
                          : null,
                      })
                    }
                  >
                    <option value="">None (Root Group)</option>
                    {groups
                      .filter((grp) => grp.id !== currentGroup.id)
                      .map((grp) => (
                        <option key={grp.id} value={grp.id}>
                          {grp.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Form onSubmit={handleUserSearch}>
            <Form.Group controlId="formEditUserSearch" className="mt-2">
              <Form.Label>Search Members</Form.Label>
              <div className="d-flex flex-row gap-2 align-items-center">
                <Form.Control
                  type="text"
                  placeholder="Search for members"
                  value={editUserSearch}
                  onChange={(e) => setEditUserSearch(e.target.value)}
                />
                <Button
                  type="button"
                  variant="primary"
                  onClick={handleUserSearch}
                >
                  Search
                </Button>
                <Button
                  type="button"
                  variant="ghost"
                  onClick={() => {
                    setEditUserSearch("");
                    setEditUserSearchResults([]);
                  }}
                >
                  Clear
                </Button>
              </div>
            </Form.Group>
          </Form>
          <Card
            className="mt-2"
            style={{ maxHeight: isMobile ? "73%" : "50vh", overflow: "auto" }}
          >
            <ListGroup>
              {currentGroup.users.map((u) => {
                const id = typeof u === "object" ? u.id : u;
                return (
                  <ListGroup.Item
                    key={id}
                    className="d-flex flex-row align-items-center"
                    active
                  >
                    <Form.Check
                      className="m-0"
                      type="checkbox"
                      label={`${u.FullNames} ${u.Surname}`}
                      checked={true}
                      id={`selected-member-${id}`}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setCurrentGroup({
                            ...currentGroup,
                            users: currentGroup.users.filter(
                              (user) => user.id !== id
                            ),
                          });
                        }
                      }}
                    />
                  </ListGroup.Item>
                );
              })}
              {editSearchResults
                .filter(
                  (user) => !currentGroup.users.some((u) => u.id === user.id)
                )
                .map((user) => (
                  <ListGroup.Item
                    key={user.id}
                    className="d-flex flex-row align-items-center"
                  >
                    <Form.Check
                      className="m-0"
                      type="checkbox"
                      id={`edit-member-${user.id}`}
                      label={`${user.FullNames} ${user.Surname}`}
                      checked={currentGroup.users.some((u) =>
                        typeof u === "object" ? u.id === user.id : false
                      )}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCurrentGroup({
                            ...currentGroup,
                            users: [...currentGroup.users, user],
                          });
                        }
                      }}
                    />
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="ghost" onClick={handleHideEditModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleEditGroup}
            disabled={!currentGroup.name.trim()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        fullscreen={isMobile}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete "{currentGroup.name}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteGroup}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
