// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this to your CSS file */
@media (max-width: 768px) {
    .form-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .form-group .flex-grow-1,
    .form-group .flex-grow-2 {
        width: 100%;
        margin-bottom: 10px;
    }

    .form-group .ms-3 {
        margin-left: 0 !important;
    }

    .d-flex.align-items-center {
        flex-direction: column;
        align-items: flex-start;
    }

    .d-flex.align-items-center .flex-grow-1,
    .d-flex.align-items-center .flex-grow-2 {
        width: 100%;
        margin-bottom: 10px;
    }

    .d-flex.align-items-center .ms-3 {
        margin-left: 0 !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/membermodal.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI;QACI,sBAAsB;QACtB,uBAAuB;IAC3B;;IAEA;;QAEI,WAAW;QACX,mBAAmB;IACvB;;IAEA;QACI,yBAAyB;IAC7B;;IAEA;QACI,sBAAsB;QACtB,uBAAuB;IAC3B;;IAEA;;QAEI,WAAW;QACX,mBAAmB;IACvB;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":["/* Add this to your CSS file */\n@media (max-width: 768px) {\n    .form-group {\n        flex-direction: column;\n        align-items: flex-start;\n    }\n\n    .form-group .flex-grow-1,\n    .form-group .flex-grow-2 {\n        width: 100%;\n        margin-bottom: 10px;\n    }\n\n    .form-group .ms-3 {\n        margin-left: 0 !important;\n    }\n\n    .d-flex.align-items-center {\n        flex-direction: column;\n        align-items: flex-start;\n    }\n\n    .d-flex.align-items-center .flex-grow-1,\n    .d-flex.align-items-center .flex-grow-2 {\n        width: 100%;\n        margin-bottom: 10px;\n    }\n\n    .d-flex.align-items-center .ms-3 {\n        margin-left: 0 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
